/* Standardized place to store global values for URLs paths - API_URLS needs to line up with endpoints.py */

export const APP_URLS = {
    login: "/login",
    rapSandbox: "/rap-sandbox",
    declarations: "/declarations",
    clients: "/clients",
    clientGenerator: (clientID: string) => `/clients/${clientID}`,
    clientRapSheetGenerator: (clientID: string) => `/clients/${clientID}/rap`,
    workflows: "/workflows",
    workflowsInsights: "/insights/workflows",
    workflowBulkGenerator: (workflowName: string) => `/workflows/${workflowName}`,
    workflowInsightsGenerator: (workflowName: string) => `/insights/workflows/${workflowName}`,
    workflowSingleGenerator: (workflowName: string, instanceID: string) => `/workflows/${workflowName}/${instanceID}`,
}

export const API_URLS = { // Needs to line up with endpoints.py
    login: "/api/login",
    logout: "/api/logout",
    testAccessToken: "/api/test-access-token",
    loadSandbox: "/api/load-sandbox",
    draftDeclaration: "/api/draft-declaration",
    getDeclarationFields: "/api/get-declaration-fields",
    getClients: "/api/get-clients",
    getClient: "/api/get-client",
    addClient: "/api/add-client",
    addClientsFromRapSheets: "/api/add-clients-from-rap-sheets",
    getClientRapSheetFile: "/api/get-client-rap-sheet-file",
    getClientRapSheetData: "/api/get-client-rap-sheet-data",
    addClientRapSheet: "/api/add-client-rap-sheet",
    generateRapSheetRequest: "/api/generate-rap-sheet-request",

    createWorkflowInstance: "/api/create-workflow-instance",
    getClientWorkflows: "/api/get-client-workflows",
    getWorkflowsMetadata: "/api/get-workflows-metadata",
    getWorkflowViewData: "/api/get-workflow-view-data",
    getWorkflowInsights: "/api/get-workflow-insights",
    getWorkflowInstance: "/api/get-workflow-instance",
    setArchivedWorkflow: "/api/set-archived-workflow",

    completeWorkflowStep: "/api/complete-workflow-step",
    revertWorkflowStep: "/api/revert-workflow-step",
    setWorkflowFlag: "/api/set-workflow-flag",
    setWorkflowTaskCheck: "/api/set-workflow-task-check",
    updateClientInfo: "/api/update-client-info",
    updateWorkflowData: "/api/update-workflow-data",
    uploadWorkflowFile: "/api/upload-workflow-file",
    getWorkflowFile: "/api/get-workflow-file",
    setWorkflowBatchLabel: "/api/set-workflow-batch-label",
    runWorkflowAction: "/api/run-workflow-action",

    getCourtDirectory: "/api/get-court-directory",
    getDADirectory: "/api/get-da-directory",
}
