import { useContext, createContext } from "react";

export const AppContext = createContext({
    userIsAuthenticated: false,
    setUserIsAuthenticated: (value: boolean) => {console.error("AppContext setter called before initialization")},

    userID: "",
    setUserID: (value: string) => {console.error("AppContext setter called before initialization")},

    userEmail: "",
    setUserEmail: (value: string) => {console.error("AppContext setter called before initialization")},

    userName: "",
    setUserName: (value: string) => {console.error("AppContext setter called before initialization")},
});

export function useAppContext() {
    return useContext(AppContext);
}
