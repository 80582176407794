import * as React from 'react';
import { Routes, Route } from 'react-router-dom';

import SandboxPage from './sandbox/sandbox'
import PrivateRoute from "../util/PrivateRoute";
import {APP_URLS} from "../util/urls";
import LoginPage from "./login/login";
import DeclarationsPage from "./declaration/declaration";
import ClientsPage from "./clients/clients";
import {WorkflowsPageHandler} from "./workflows/workflows";
import {InsightsPageHandler} from "./insights/insights";

function Main() {
    return (
    <Routes>
        <Route path={APP_URLS.login} element={<LoginPage/>} />
        <Route path={APP_URLS.rapSandbox} element={<PrivateRoute component={SandboxPage}/>} />
        <Route path={APP_URLS.declarations} element={<PrivateRoute component={DeclarationsPage}/>} />
        <Route path={`${APP_URLS.clients}*`} element={<PrivateRoute component={ClientsPage}/>} />
        <Route path={`${APP_URLS.workflows}*`} element={<PrivateRoute component={WorkflowsPageHandler}/>} />
        <Route path={`${APP_URLS.workflowsInsights}*`} element={<PrivateRoute component={InsightsPageHandler}/>} />
        <Route path={'/'} element={<PrivateRoute component={WorkflowsPageHandler}/>} />
    </Routes>
  );
}

export default Main;
