import * as React from 'react';
import './sandbox.css';
import {Container, Form, Row, Col, Badge, Button} from 'react-bootstrap';
import {API_URLS} from "../../util/urls";
import ReactJson from 'react-json-view'
import Dropzone from 'react-dropzone'
import {TopNavbar} from "../../components/navbar/navbar";

type GrammarSlot = {
    name: string,
    value: string,
    sourceText: string
}

type GrammarSlotMap = {
  [key: string]: GrammarSlot;
};

type GrammarParseResult = {
    grammar: string,
    grammarMatchLabel: string,
    text: string,
    slots: GrammarSlotMap,
    children: Array<GrammarParseResult>
}

type SandboxResults = {
    file: string,
    text: string,
    data: GrammarParseResult,
}

type SandboxDocument = {
    fileName: string,
    pdfPath: string,
    results: SandboxResults,
    slotsResults: any
    extracted: any
}

export function DocPDFView({blob}: {blob: any}) {
    return <div className={'doc-pdf-view'}>
        <iframe
            // src={doc.pdfPath}
            src={URL.createObjectURL(blob)}
            frameBorder="0" height="100%" width="100%">
        </iframe>
    </div>
}

function DocTextView({doc}: {doc: SandboxDocument}) {
    return <div className={'doc-text-view'}>
        {doc.results.text}
    </div>
}

function DocChunk({c}: {c: GrammarParseResult}) {
    const slotBadges = Object.values(c.slots).map((s) => <Badge bg={'success'}>{s.name}: {JSON.stringify(s.value, undefined, 1)}</Badge>)

    if (c.children.length > 0) {
        return <div className={'doc-chunk parent-chunk'}>
            {c.grammarMatchLabel ? <Badge>{c.grammarMatchLabel}</Badge> :  <Badge bg={'secondary'}>no match</Badge>}
            {slotBadges}
            {c.children.map((cc) => <DocChunk c={cc}/>)}
        </div>
    }

    return <div className={'doc-chunk'}>
        {c.grammarMatchLabel ? <Badge>{c.grammarMatchLabel}</Badge> :  <Badge bg={'secondary'}>no match</Badge>}
        {slotBadges}
       <div className={'doc-chunk-text'}>{c.text}</div>
    </div>
}

function DocChunksView({doc}: {doc: SandboxDocument}) {
    return <div className={'doc-text-view'}>
        {/*{doc.results.data.children.map((c) => {*/}
        {/*    return <React.Fragment>*/}
        {/*        <DocChunk c={c} />*/}
        {/*        <hr/>*/}
        {/*    </React.Fragment>*/}

        {/*})}*/}
        <DocChunk c={doc.results.data} />
        </div>
}


function DocDataView({doc}: {doc: SandboxDocument}) {
    return <div className={'doc-text-view'}>
        <ReactJson
            src={doc.slotsResults}
            displayObjectSize={false}
            displayDataTypes={false}
            indentWidth={3}
            style={{fontSize: "12px"}}
        />
    </div>
}


function DocExtractedView({doc}: {doc: SandboxDocument}) {
    return <div className={'doc-text-view'}>
        <ReactJson
            src={doc.extracted}
            displayObjectSize={false}
            displayDataTypes={false}
            indentWidth={3}
            style={{fontSize: "12px"}}
        />
    </div>
}

type MatchDuplicate = {
    match: GrammarParseResult;
    count: number;
}

function dedupMatches(matches: GrammarParseResult[]): MatchDuplicate[] {
    const duplicateMap: Record<string, number> = {};
    const matchLookup: Record<string, GrammarParseResult> = {};

    // Count occurrences of each text
    for (const match of matches) {
        const text = match.text;
        duplicateMap[text] = (duplicateMap[text] || 0) + 1;
        matchLookup[text] = match;
    }

    let duplicates: MatchDuplicate[] = Object.entries(duplicateMap).map(([text, count]) => ({ match: matchLookup[text], count }));

    // Sort alphabetically
    duplicates.sort((a, b) => a.match.text.localeCompare(b.match.text))
    // duplicates.sort((a, b) => b.count - a.count);

    return duplicates;
}

function MatchListView({matches}: {matches: GrammarParseResult[]}) {
    return <div className={'doc-text-view'}>
        {dedupMatches(matches).map(({match, count}) =>
            <React.Fragment>
                <strong>{count} occurrence(s):</strong>
                <DocChunk c={match} />
            </React.Fragment>
        )}
    </div>
}


function mergeKeyToListObjs(obj1: { [key: string]: GrammarParseResult[] }, obj2: { [key: string]: GrammarParseResult[] }): { [key: string]: GrammarParseResult[] } {
  const result: { [key: string]: GrammarParseResult[] } = {};

  // Merge keys from obj1
  Object.keys(obj1).forEach(key => {
    result[key] = obj1[key].concat(obj2[key] || []);
  });

  // Merge keys from obj2 that are not present in obj1
  Object.keys(obj2).forEach(key => {
    if (!obj1[key]) {
      result[key] = obj2[key];
    }
  });

  return result;
}

function getMatchesByTypeHelper(gpr: GrammarParseResult) {
    let results = {
        [gpr.grammar]: [gpr]
    }

    for (const c of gpr.children) {
        const newResults = getMatchesByTypeHelper(c);
        results = mergeKeyToListObjs(results, newResults)
    }

    return results;
}

function getMatchesByType(docs: Array<SandboxDocument>) {
    let results = {}

    for (const d of docs) {
        const newResults = getMatchesByTypeHelper(d.results.data);
        results = mergeKeyToListObjs(results, newResults)
    }

    return results;
}

function SandboxPage() {
    const [viewMode, setViewMode] = React.useState<string>("document")
    const [docs, setDocs] = React.useState<Array<SandboxDocument>>([])
    const [uploadedFiles, setUploadedFiles] = React.useState<any>([])
    const [matchOptions, setMatchOptions] = React.useState<any>([])
    const [currentDocIdx, setCurrentDocIdx] = React.useState(0)
    const [currentMatchKey, setCurrentMatchKey] = React.useState("")
    const [showParse, setShowParse] = React.useState(false)
    const [showRollUp, setShowRollUp] = React.useState(false)

    function uploadFiles(files: any[]) {
        const data = new FormData();

        setUploadedFiles(files);

        files.map((file) => {
            data.append('file', file);
        });

        fetch(API_URLS.loadSandbox, {
            method: 'POST',
            body: data
        }).then( res => Promise.all([res.status, res.json()])).then(res => {
            let status = res[0]
            let data = res[1]

            if (status === 200) {
                setDocs(data.documents)
                setMatchOptions(getMatchesByType(data.documents))
            }

            return {status, data}
        });
    }


    if (docs.length === 0) {
        return (<React.Fragment>
            <TopNavbar/>
            <Container fluid>
                <br/>
                <br/>
                <Dropzone onDrop={acceptedFiles => uploadFiles(acceptedFiles)}>
                  {({getRootProps, getInputProps}) => (
                    <section>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <Button>Upload Rap Sheets</Button>
                      </div>
                    </section>
                  )}
                </Dropzone>
            </Container>
        </React.Fragment>
    );
    }

    return (<React.Fragment>
            <TopNavbar/>
            <Container fluid>
                <header className={'sandbox-header'}>
                    <Form.Select onChange={(e) => setViewMode(e.target.value)}>
                        <option value={'document'}>Documents</option>
                        <option value={'matches'}>Parsing Matches</option>
                    </Form.Select>

                    {viewMode === 'document' && <React.Fragment>
                        <Form.Select onChange={(e) => setCurrentDocIdx(parseInt(e.target.value))}>
                        {docs.map((d, idx)=>{
                            return <option value={idx}>{d.fileName}</option>
                        })}
                    </Form.Select>
                    <Form.Check // prettier-ignore
                        type={'checkbox'}
                        id={`show-parse`}
                        label={`Show Parse`}
                        checked={showParse}
                        onChange={(e) => setShowParse(e.target.checked)}
                      />
                    <Form.Check // prettier-ignore
                        type={'checkbox'}
                        id={`show-rollup`}
                        label={`Show Roll Up`}
                        checked={showRollUp}
                        onChange={(e) => setShowRollUp(e.target.checked)}
                      />
                    </React.Fragment>
                        }
                    {viewMode === 'matches' && <Form.Select onChange={(e) => setCurrentMatchKey(e.target.value)}>
                        {Object.keys(matchOptions).map((mo, idx)=>{
                            return <option value={mo}>{mo}</option>
                        })}
                    </Form.Select>}
                </header>

                {viewMode === 'document' && <Row className={'sandbox-contents-row'}>
                    {/*<Col>{docs[currentDocIdx] && <DocPDFView doc={docs[currentDocIdx]}/>}</Col>*/}
                    <Col>{docs[currentDocIdx] && <DocPDFView blob={uploadedFiles[currentDocIdx]}/>}</Col>
                    {/*<Col>{docs[currentDocIdx] && <DocTextView doc={docs[currentDocIdx]}/>}</Col>*/}
                    {showParse && <Col>{docs[currentDocIdx] && <DocChunksView doc={docs[currentDocIdx]}/>}</Col>}
                    {showRollUp && <Col>{docs[currentDocIdx] && <DocDataView doc={docs[currentDocIdx]}/>}</Col>}
                    <Col>{docs[currentDocIdx] && <DocExtractedView doc={docs[currentDocIdx]}/>}</Col>
                </Row>}

                {viewMode === 'matches' && <Container>
                    <Row className={'sandbox-contents-row'}>
                        <Col>{matchOptions[currentMatchKey] && <MatchListView matches={matchOptions[currentMatchKey] }/>}</Col>
                    </Row>
                </Container>}

            </Container>
    </React.Fragment>
    );
}

export default SandboxPage;
