import {Client} from "../clients/clients";
import * as React from "react";
import {API_URLS} from "../../util/urls";
import {Button, Col, Container, Form, Modal, Row} from "react-bootstrap";
import {WorkflowHistory} from "./ca12034/ca12034";
import {WorkflowHandlers} from "./workflows";

export type User = {
    userID: number,
    name: string,
    email: string,
    role: string,
}

export type WorkflowStateChange = {
    uuid: string,
    workflow_instance_uuid: string,
    timestamp: string,
    from_step: string,
    to_step: string,
    from_flagged: boolean,
    to_flagged: boolean,
    agent: User,
    notes: string,
    is_revert: boolean,
}

export type WorkflowEditLog = {
    id: number,
    workflow_instance_uuid: string,
    timestamp: string,
    value_label: string,
    from_value: string,
    to_value: boolean,
    agent: User,
}

export type WorkflowInstance<T> = {
    uuid: string,
    client: Client,
    created_at: string,
    workflow_name: string,
    current_step: string,
    flagged: boolean,
    data: { checked: Array<string> } & T,
    history: Array<WorkflowStateChange>,
    editLogs: Array<WorkflowEditLog>,
    uploadedFiles: Array<string>,
    batch_label: string,
    archived: boolean,
}

export type StepCheckTask = {
    name: string,
    description: string,
}

export type StepMetadata = {
    name: string,
    displayName: string,
    numInstances: number,
    numFlags: number,
}

export type StepComponentProps = {
    wi: WorkflowInstance<any>,
    refresh: any,  // For refreshing instance
    refreshMetadata: any  // For refreshing entire workflow page
}

export type WorkflowStepHandler = {
    sortIdx: number,
    name: string,
    displayName: string,
    icon: any,
    component?: (props: StepComponentProps) => Element,
    validate?: (wi: WorkflowInstance<any>) => { valid: boolean, msg: string },
    checkTasks: Array<StepCheckTask>
}

export type WorkflowHandler = {
    name: string,
    displayName: string,
    steps: Record<string, WorkflowStepHandler | undefined>
}

export const completeStart = (currentInstance: WorkflowInstance<any>, cb: any) => {
    if (!currentInstance || currentInstance.current_step !== "START") {
        return
    }

    fetch(API_URLS.completeWorkflowStep, {
        method: 'POST',
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({
            stepName: currentInstance.current_step,
            instanceID: currentInstance.uuid,
            notes: ""
        })
    }).then(res => Promise.all([res.status, res.json()])).then(res => {
        let status = res[0]
        let data = res[1]

        if (status === 200) {
            cb()
        }

        return {status, data}
    });
}

export type StepChecklistProps = {
    wi: WorkflowInstance<any>,
    tasks: Array<StepCheckTask>,
    refresh: any
}

export type StepChecklistItemProps = {
    wi: WorkflowInstance<any>,
    task: StepCheckTask,
    refresh: any
}

export function StepChecklistItem({wi, task, refresh}: StepChecklistItemProps) {
    const checkedProp = wi.data.checked.includes(task.name)
    const [checked, setChecked] = React.useState<boolean>(checkedProp);

    React.useEffect(() => {
        setChecked(checkedProp)
    }, [wi])


    function updateChecked(newVal: boolean) {
        setChecked(newVal);

        fetch(API_URLS.setWorkflowTaskCheck, {
            method: 'POST',
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({
                taskName: task.name,
                instanceID: wi.uuid,
                value: newVal
            })
        }).then(res => Promise.all([res.status, res.json()])).then(res => {
            let status = res[0]
            let data = res[1]

            if (status === 200) {
                refresh()
            }

            return {status, data}
        });
    }

    return <div className={'step-checklist-item'}>
        <Form.Check
            inline
            label={task.description}
            type={"checkbox"}
            checked={checked}
            onChange={(e) => updateChecked(e.target.checked)}
        />
    </div>
}

export function StepChecklist({wi, tasks, refresh}: StepChecklistProps) {
    return <div className={'workflow-checklist'}>
        <h5>Checklist</h5>
        {tasks.map((t) => <StepChecklistItem wi={wi} task={t} refresh={refresh}/>)}
    </div>
}

export function WorkflowHistoryItem({wi, h}: { h: WorkflowStateChange, wi: WorkflowInstance<any> }) {
    // @ts-ignore
    const workflowHandler = WorkflowHandlers[wi.workflow_name];

    const toStepHandler = workflowHandler.steps[h.to_step];
    const fromStepHandler = workflowHandler.steps[h.from_step];

    const options = {
        year: "2-digit",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        timeZone: 'UTC',
    };
    // @ts-ignore
    const timeString = (new Date(h.timestamp)).toLocaleDateString("en", options);

    let message = "";
    let variant = "";

    if (h.from_step === h.to_step) { // Flag change
        if (h.to_flagged) {
            message = `Flagged for review`;
            variant = "text-danger";
        } else {
            message = `Removed flag`;
            variant = "text-success";
        }
    } else {  // Step change
        if (h.is_revert) {
            message = `Reverted to step: ${toStepHandler.displayName}`;
            variant = "text-danger";
        } else {
            message = `Completed step: ${fromStepHandler.displayName}`;
            variant = "text-success";
        }
    }

    return <div className={'history-item'}>
        <div className={'history-item-header'}>
            <div className={'history-timestamp'}>{timeString}</div>
            <div className={'history-agent'}>{h.agent.name}</div>
        </div>
        <div className={`history-message ${variant}`}>{message}</div>
        {h.notes && <div className={`history-note`}>{h.notes}</div>}
    </div>
}

export function WorkflowEditItem({wi, el}: { el: WorkflowEditLog, wi: WorkflowInstance<any> }) {
    const options = {
        year: "2-digit",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        timeZone: 'UTC',
    };
    // @ts-ignore
    const timeString = (new Date(el.timestamp)).toLocaleDateString("en", options);

    let message = `Changed ${el.value_label}`;
    let valMessage = el.from_value ? `From "${el.from_value}" to "${el.to_value}"` : `${el.to_value}`

    return <div className={'history-item'}>
        <div className={'history-item-header'}>
            <div className={'history-timestamp'}>{timeString}</div>
            <div className={'history-agent'}>{el.agent.name}</div>
        </div>
        <div className={`history-message text-primary`}>{message}</div>
        <div className={`history-note`}>{valMessage}</div>
    </div>
}

export type WorkflowMetadata = WorkflowHandler & {
    numberActive: number
}

export function CompleteStepModal({
                                      show,
                                      setShow,
                                      onComplete,
                                      instance
                                  }: { show: boolean, setShow: any, onComplete: any, instance: WorkflowInstance<any> }) {
    const [notes, setNotes] = React.useState<string>("");
    const [completing, setCompleting] = React.useState<boolean>(false);

    // @ts-ignore
    const workflowHandler = WorkflowHandlers[instance.workflow_name];

    const currentStepHandler = workflowHandler.steps[instance.current_step]

    const handleSubmit = (e: any) => {
        e.preventDefault();
        e.stopPropagation();

        setCompleting(true);

        fetch(API_URLS.completeWorkflowStep, {
            method: 'POST',
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({
                stepName: instance.current_step,
                instanceID: instance.uuid,
                notes: notes
            })
        }).then(res => Promise.all([res.status, res.json()])).then(res => {
            let status = res[0]
            let data = res[1]

            if (status === 200) {
                setShow(false)
                setCompleting(false);
                setNotes("")
                onComplete()
            }

            return {status, data}
        });

    }


    return (
        <Modal show={show} onHide={() => {
            setShow(false)
        }}>
            <Modal.Header closeButton>
                <Modal.Title>Mark step as completed?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    Client: <strong>{instance.client.name.firstName} {instance.client.name.lastName}</strong>
                </div>
                <div>
                    Step: <strong>{currentStepHandler.displayName}</strong>
                </div>
                <br/>
                <Form onSubmit={handleSubmit}>
                    <Form.Label className={'fw-700'}>Notes</Form.Label>
                    <Form.Control type="text" placeholder={"Add any notes..."} value={notes} onChange={(e) => {
                        setNotes(e.target.value)
                    }}/>
                    <br/>
                    <Button type="submit" variant="primary" disabled={completing}>
                        {completing ? "Completing..." : "Mark Completed"}
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export function ChangeFlagModal({
                                    show,
                                    setShow,
                                    onComplete,
                                    instance
                                }: { show: boolean, setShow: any, onComplete: any, instance: WorkflowInstance<any> }) {
    const [notes, setNotes] = React.useState<string>("");


    const toggleFlag = () => {
        fetch(API_URLS.setWorkflowFlag, {
            method: 'POST',
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({
                value: !instance?.flagged,
                instanceID: instance?.uuid,
                notes: notes
            })
        }).then(res => Promise.all([res.status, res.json()])).then(res => {
            let status = res[0]
            let data = res[1]

            if (status === 200) {
                onComplete()
            }

            return {status, data}
        });
    }

    // @ts-ignore
    const workflowHandler = WorkflowHandlers[instance.workflow_name];

    const currentStepHandler = workflowHandler.steps[instance.current_step]

    const handleSubmit = (e: any) => {
        e.preventDefault();
        e.stopPropagation();

        fetch(API_URLS.setWorkflowFlag, {
            method: 'POST',
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({
                value: !instance?.flagged,
                instanceID: instance?.uuid,
                notes: notes
            })
        }).then(res => Promise.all([res.status, res.json()])).then(res => {
            let status = res[0]
            let data = res[1]

            if (status === 200) {
                setShow(false)
                setNotes("")
                onComplete()
            }

            return {status, data}
        });
    }


    return (
        <Modal show={show} onHide={() => {
            setShow(false)
        }}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {instance?.flagged ? "Unflag?" : "Flag for Review?"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    Client: <strong>{instance.client.name.firstName} {instance.client.name.lastName}</strong>
                </div>
                <div>
                    Step: <strong>{currentStepHandler.displayName}</strong>
                </div>
                <br/>
                <Form onSubmit={handleSubmit}>
                    <Form.Label className={'fw-700'}>Notes</Form.Label>
                    <Form.Control type="text" placeholder={"Add any notes..."} value={notes} onChange={(e) => {
                        setNotes(e.target.value)
                    }}/>
                    <br/>
                    <Button type="submit" variant="primary" disabled={false}>
                        {instance?.flagged ? "Unflag" : "Flag for Review"}
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export function InstanceDetailsModal({
                                         show,
                                         setShow,
                                         triggerRefresh,
                                         instance
                                     }: { show: boolean, setShow: any, triggerRefresh: any, instance: WorkflowInstance<any> }) {
    const [batchLabel, setBatchLabel] = React.useState<string>(instance.batch_label);
    const [archiveConfirm, setArchiveConfirm] = React.useState<boolean>(false);

    React.useEffect(() => {
        setBatchLabel(instance.batch_label);
    }, [instance])

    function updateBatchLabel() {
        return fetch(API_URLS.setWorkflowBatchLabel, {
            method: 'POST',
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({
                instanceID: instance.uuid,
                batch: batchLabel,
            })
        }).then(res => Promise.all([res.status, res.json()])).then(res => {
            let status = res[0]
            let data = res[1]

            triggerRefresh()

            if (status === 200) {

            } else {
                alert("Could not update workflow batch label")  // TODO use global notifications for errors
            }

            return {status, data}
        });
    }

    function archiveWorkflow(value: boolean) {
        return fetch(API_URLS.setArchivedWorkflow, {
            method: 'POST',
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({
                instanceID: instance.uuid,
                archived: value,
            })
        }).then(res => Promise.all([res.status, res.json()])).then(res => {
            let status = res[0]
            let data = res[1]


            if (status === 200) {
                setArchiveConfirm(false)
                triggerRefresh()
                setShow(false)
            }

            return {status, data}
        });
    }

    // @ts-ignore
    const workflowHandler = WorkflowHandlers[instance.workflow_name];


    return (
        <Modal show={show} onHide={() => {
            setArchiveConfirm(false);
            setShow(false)
        }} className={'workflow-details-modal'}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Workflow: {instance.client.name.firstName} {instance.client.name.lastName}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className={'h-100'}>
                    <Col>
                        <Form.Label className={'fw-700'}>Batch Label</Form.Label>
                        <Form.Control
                            type={"text"}
                            placeholder="Batch Label"
                            value={batchLabel}
                            onChange={(e) => setBatchLabel(e.target.value)}
                            onBlur={updateBatchLabel}
                        />
                        <br/>
                        <hr/>
                        {instance.archived
                            ? (<Button variant={archiveConfirm ? 'success' : 'outline-success'} onClick={() => {
                                return archiveConfirm ? archiveWorkflow(false) : setArchiveConfirm(true)
                            }}>
                                {archiveConfirm ? "Are you sure?" : "Un-archive Workflow"}
                            </Button>)
                            : (<Button variant={archiveConfirm ? 'danger' : 'outline-danger'} onClick={() => {
                                return archiveConfirm ? archiveWorkflow(true) : setArchiveConfirm(true)
                            }}>
                                {archiveConfirm ? "Are you sure?" : "Archive Workflow"}
                            </Button>)
                        }
                    </Col>
                    <Col>
                        <WorkflowHistory wi={instance}/>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    )
}

export function RevertStepModal({
                                    show,
                                    setShow,
                                    onComplete,
                                    instance
                                }: { show: boolean, setShow: any, onComplete: any, instance: WorkflowInstance<any> }) {
    const [stepName, setStepName] = React.useState<string>("");
    const [notes, setNotes] = React.useState<string>("");

    // @ts-ignore
    const workflowHandler = WorkflowHandlers[instance.workflow_name];

    const currentStepHandler = workflowHandler.steps[instance.current_step]

    const handleSubmit = (e: any) => {
        e.preventDefault();
        e.stopPropagation();

        fetch(API_URLS.revertWorkflowStep, {
            method: 'POST',
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({
                stepName: stepName,
                instanceID: instance.uuid,
                notes: notes
            })
        }).then(res => Promise.all([res.status, res.json()])).then(res => {
            let status = res[0]
            let data = res[1]

            if (status === 200) {
                setShow(false)
                setNotes("")
                onComplete()
            }

            return {status, data}
        });

    }

    const stepsBefore = (Object.values(workflowHandler.steps) as Array<WorkflowStepHandler>).filter((step) => step.sortIdx < currentStepHandler.sortIdx).reverse();

    return (
        <Modal show={show} onHide={() => {
            setShow(false)
        }}>
            <Modal.Header closeButton>
                <Modal.Title>Revert to previous step?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    Client: <strong>{instance.client.name.firstName} {instance.client.name.lastName}</strong>
                </div>
                <div>
                    Current Step: <strong>{currentStepHandler.displayName}</strong>
                </div>
                <br/>
                <Form onSubmit={handleSubmit}>
                    <Form.Label className={'fw-700'}>Revert To</Form.Label>
                    <Form.Select onChange={(e) => {
                        setStepName(e.target.value)
                    }} value={stepName}>
                        <option value={''}>Choose a step...</option>
                        {stepsBefore.map((step: WorkflowStepHandler) => <option
                            value={step.name}>{step.displayName}</option>)}
                    </Form.Select>
                    <br/>
                    <Form.Label className={'fw-700'}>Notes</Form.Label>
                    <Form.Control type="text" placeholder={"Add any notes..."} value={notes} onChange={(e) => {
                        setNotes(e.target.value)
                    }}/>
                    <br/>
                    <Button type="submit" variant="primary" disabled={!stepName}>
                        Revert
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export function DefaultStepComponent(stepName: string) {
    const component = ({}: StepComponentProps) => {
        return <Container>
            Coming soon... {stepName}
        </Container>
    }

    return component;
}

export function dateToMMDDYYYY(date: Date) {
    // Extract the month, day, and year from the date
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();

    // Return the formatted date string
    return `${month}-${day}-${year}`;
}

export function formatDate(d: string | null): string {
    if (!d) return "";

    const options = {
        year: "2-digit",
        month: "numeric",
        day: "numeric",
        // timeZone: 'UTC',
    };
    // @ts-ignore
    const timeString = (new Date(d)).toLocaleDateString("en", options);
    return timeString;
}

export function formatDateObj(d: any): string {
    if (!d) return "";

    const options = {
        year: "2-digit",
        month: "numeric",
        day: "numeric",
        // timeZone: 'UTC',
    };
    // @ts-ignore
    const timeString = d.toLocaleDateString("en", options);
    return timeString;
}

function removeTimeAndTimezone(date: Date) {
    // Extract the date part in UTC
    const dateString = date.toISOString().split('T')[0];
    // Create a new Date object using the extracted date string
    return new Date(dateString);
}

export function parseDate(dateString: string) {
    const datePart = dateString.split(' ').slice(1, 4).join(' ');
    const d = removeTimeAndTimezone(new Date(datePart));
    return d;
}

export function dateFromMMDDYYYY(dateString: string) {
    const [month, day, year] = dateString.split("-").map(Number);
    // Subtract 1 from the month because the Date constructor expects zero-based months (0 for January, 11 for December)
    return new Date(year, month - 1, day);
}