import * as React from 'react';
import './login.css';
import {APP_URLS} from "../../util/urls";
import {useLocation} from "react-router-dom";
import {useAppContext} from "../../util/context";
import {login} from "../../util/auth";
import {Link, useNavigate} from "react-router-dom";
import {Button, Card, Form, Alert, Row, Col, Container} from 'react-bootstrap';

type LoginCardProps = {
  redirectPath: string;
};

function LoginCard({ redirectPath }: LoginCardProps) {
    const appContext = useAppContext();
    const navigate = useNavigate();

    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");

    const [validated, setValidated] = React.useState(false);
    const [incorrectPassword, setIncorrectPassword] = React.useState(false);
    const [loginSuccessful, setLoginSuccessful] = React.useState(false);


    function runLogin(providedEmail: string, providedPassword: string) {
        login(providedEmail, providedPassword, appContext).then((res) => {
            let { status, data } = res;
            if (status === 200) {
                // Success!
                setLoginSuccessful(true)

                navigate(redirectPath);
            } else {
                setIncorrectPassword(true);
            }
        });
    }

    function handleLoginPress(e: any) {
        e.preventDefault() ;

        const form = e.currentTarget;

        if (form.checkValidity() === false) {
          e.preventDefault();
          e.stopPropagation();

          setValidated(true);
          return;
        }

        setValidated(true);
        runLogin(email, password)
    }

    const ErrorMessage = () =>{
        if (incorrectPassword) {
            return(
                <Alert variant={"danger"}>
                   Sorry, there isn’t an account with those credentials.
                </Alert>
            )
        } else {
            return null
        }
    };


    // Handle password reset or onboarding
    if (loginSuccessful) {
        navigate(redirectPath)
    }


    return <Card className={"login-card text-start shadow-lg p-3 mb-5 bg-white"}>
                <Card.Body>
                    <ErrorMessage/>

                    <Form onSubmit={handleLoginPress} validated={validated} noValidate>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                required type="email" placeholder={'Your email'} name="email"
                                value={email} onChange={(e: any)=>{setEmail(e.target.value)}}
                            />
                            <Form.Control.Feedback type={"invalid"}>Please enter a valid email</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                    required type="password" placeholder={'Your password'} name="password"
                                    value={password} onChange={(e: any)=>{setPassword(e.target.value)}}
                                />
                                <Form.Control.Feedback type={"invalid"}>Please enter a valid password</Form.Control.Feedback>
                            </Form.Group>

                        <Button variant="outline-primary" type="submit" className={"login-button"}>
                            Login
                        </Button>

                    </Form>
                </Card.Body>
            </Card>
}


interface loginState {
    redirect: string;
}

function LoginPage() {
    let locationState = useLocation().state as loginState;
    if (locationState === null) {
        locationState = {redirect: APP_URLS.rapSandbox} // Default to rap sandbox
    }
    const redirect = locationState.redirect

    return (
            <Container>
                <header className={"my-4"}>
                    <img className={'login-logo'} src={"logo.jpeg"} />
                </header>
                <Row className="d-flex justify-content-center">
                    <Col sm={6} className={'login-page-col'}>
                        <LoginCard redirectPath={redirect} />
                    </Col>
                </Row>
            </Container>
    );
}

export default LoginPage;
