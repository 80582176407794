import * as React from 'react';
import './declaration.css';
import {Container, Form, Row, Col, Badge, Button} from 'react-bootstrap';
import {API_URLS} from "../../util/urls";
import {TopNavbar} from "../../components/navbar/navbar";
import { ToggleSlider }  from "react-toggle-slider";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export type DynamicFieldProps = {
    name: string,
    prompt: string,
    defaultValue: any,
    value: any,
    type: string,
    required: boolean,
    options?: Array<string>,
    conditionalRender: string,
    onChange: (newVal: any) => void
}

export function DynamicField({name, prompt, required, type, onChange, defaultValue, options, value}: DynamicFieldProps) {
    const [currentValue, setCurrentValue] = React.useState(value);
    const [selectedDate, setSelectedDate] = React.useState(value ? new Date(value) : new Date());

    React.useEffect(() => {
        setCurrentValue(value)
    }, [value])

    // TODO use required / validate
    // TODO use defaultValue everywhere

    if (type === "string") {
        return <div className={'dynamic-field'}>
            <div className={'dynamic-field-prompt'}>{prompt}</div>
            <Form.Control value={currentValue} onChange={(e) => {setCurrentValue(e.target.value)}} onBlur={(e) => {onChange(e.target.value)}} defaultValue={defaultValue}/>
        </div>
    }

    if (type === "choice") {
        return <div className={'dynamic-field'}>
            <div className={'dynamic-field-prompt'}>{prompt}</div>
            <Form.Select onChange={(e) => {onChange(e.target.value)}} value={value}>
                {options?.map((option) => <option value={option}>{option}</option>)}
            </Form.Select>
        </div>
    }

    if (type === "boolean") {
        return <div className={'dynamic-field'}>
            <div className={'dynamic-field-prompt slider-field'}>{prompt} <ToggleSlider onToggle={onChange} active={value !== undefined ? value : defaultValue}/></div>
        </div>
    }

    if (type === "date") {
        return <div className={'dynamic-field'}>
            <div className={'dynamic-field-prompt'}>{prompt}</div>
            <DatePicker selected={selectedDate} onChange={(newVal: Date)=> {setSelectedDate(newVal); onChange(newVal)}} />
        </div>
    }


    return <div>INVALID FIELD: {prompt} {options}</div>
}

function DeclarationsPage() {
    const [declarationFields, setDeclarationFields] = React.useState([]);
    const [declarationFieldValues, setDeclarationFieldValues] = React.useState<{ [key: string]: any; }>({});
    const [declarationParagraphs, setDeclarationParagraphs] = React.useState([]);

    React.useEffect(() => {
        fetch(API_URLS.getDeclarationFields, {
            method: 'GET',
        }).then( res => Promise.all([res.status, res.json()])).then(res => {
            let status = res[0]
            let data = res[1]

            if (status === 200) {
                setDeclarationFields(data.fields)

                const defaultValMapping = {};
                data.fields.forEach((obj: DynamicFieldProps) => {
                    // @ts-ignore
                    defaultValMapping[obj.name] = obj.defaultValue;
                });

                setDeclarationFieldValues(defaultValMapping)
            }

            return {status, data}
        });
    }, [])

    function draftDeclaration() {
        fetch(API_URLS.draftDeclaration, {
            method: 'POST',
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({fields: declarationFieldValues})
        }).then( res => Promise.all([res.status, res.json()])).then(res => {
            let status = res[0]
            let data = res[1]

            if (status === 200) {
                setDeclarationParagraphs(data.declaration)
            }

            return {status, data}
        });
    }

    function handleFieldUpdate(fieldName: string, newVal: any) {
        // Copy
        let newFieldVals = JSON.parse(JSON.stringify(declarationFieldValues))

        // Add new value
        newFieldVals[fieldName] = newVal;

        // Update state
        setDeclarationFieldValues(newFieldVals)
    }

    return (<React.Fragment>
            <TopNavbar/>
            <Container fluid className={'declaration-main-section'}>
                <Row className={'declarations-contents-row'}>
                    <Col className={"declarations-form-col"}>
                        <div className="declaration-form">
                            {declarationFields.map((df: DynamicFieldProps) => (!df['conditionalRender'] || declarationFieldValues[df['conditionalRender']]) && <DynamicField {...df} onChange={(newVal: any) => handleFieldUpdate(df.name, newVal)}/>)}
                            <br/>
                            <Button onClick={draftDeclaration}>Draft!</Button>
                        </div>
                    </Col>
                    <Col className={"declarations-text-col"}>
                        {declarationParagraphs.length === 0 && <em>Press the "Draft" button to draft a declaration</em>}
                        {declarationParagraphs.length > 0 && <Button className={'copy-button'} variant={'outline-success'} onClick={() => {navigator.clipboard.writeText(declarationParagraphs.join("\n\n"))}}>Copy to Clipboard</Button>}
                        {declarationParagraphs.map((p) => <p>{p}</p>)}
                    </Col>
                </Row>

            </Container>
        </React.Fragment>
    );
}

export default DeclarationsPage;
