import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import {Button} from "react-bootstrap";
import {APP_URLS} from "../../util/urls";
import * as React from "react";
import {useAppContext} from "../../util/context";
import {logout} from "../../util/auth";
import NavDropdown from 'react-bootstrap/NavDropdown';


export function TopNavbar() {
    const appContext = useAppContext();

    return <Navbar expand="lg" className="bg-body-tertiary">
        <Container>
            <Navbar.Brand href="#home">Avenues Tech</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
                <Nav.Link href={APP_URLS.workflows}>Workflows</Nav.Link>
                <Nav.Link href={APP_URLS.clients}>Clients</Nav.Link>
                <NavDropdown title="Research" id="rd-nav-dropdown">
                    <NavDropdown.Item href={APP_URLS.rapSandbox}>
                        Rap Sheets
                    </NavDropdown.Item>
                    {/*<NavDropdown.Item href={APP_URLS.declarations}>*/}
                    {/*    Declarations*/}
                    {/*</NavDropdown.Item>*/}
                </NavDropdown>
            </Nav>
            </Navbar.Collapse>
            <Button onClick={()=>{logout(appContext)}}>Log Out</Button>
        </Container>
    </Navbar>
}