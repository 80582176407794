import * as React from 'react';
import './insights.css';
import {API_URLS, APP_URLS} from "../../util/urls";
import {useMatch, useNavigate} from "react-router-dom";
import {useAppContext} from "../../util/context";
import {TopNavbar} from "../../components/navbar/navbar";
import LoadingSplash from "../../components/loading-splash/loading-splash";
import {WorkflowHandlers} from "../workflows/workflows";
import {WorkflowInstance} from "../workflows/common";
import {logout} from "../../util/auth";



function WorkflowInsightsPage({workflowName}: {workflowName: string}) {
    const navigate = useNavigate();
    const appContext = useAppContext();

    const [loading, setLoading] = React.useState<boolean>(true);
    const [insightsData, setInsightsData] = React.useState({});

    React.useEffect(() => {
        // Refresh metadata
        fetch(API_URLS.getWorkflowInsights, {
            method: 'POST',
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({workflowName: workflowName})
        }).then( res => Promise.all([res.status, res.json()])).then(res => {
            let status = res[0]
            let data = res[1]

            setLoading(false)

            if (status === 200) {
                setInsightsData(data)
            }
            else if (status === 401) {
                logout(appContext)
                navigate(APP_URLS.login)
            }

            return {status, data}
        });
    }, [])

    // @ts-ignore
    const workflowHandler = WorkflowHandlers[workflowName];

    // Loading
    if (loading) {
        return <LoadingSplash/>
    }

    return (<React.Fragment>
            {/* Content */}
            <TopNavbar/>
            <div className={'workflow-title'}>
                Insights – {workflowHandler.displayName}
            </div>
            <div className={'workflow-container'}>
                {JSON.stringify(insightsData)}
            </div>

        </React.Fragment>
    );
}


export function InsightsPageHandler() {
    // Get URL params
    const matchWithWorkflow = useMatch(`${APP_URLS.workflowsInsights}/:workflowName`);
    const matchBase = useMatch(`${APP_URLS.workflowsInsights}`);
    const match =  matchWithWorkflow || matchBase

    //@ts-ignore
    const workflowName = match?.params.workflowName || "";

    if (workflowName) {
        return <WorkflowInsightsPage workflowName={workflowName}/>
    }

    return <></>
}
