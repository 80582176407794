/* Login/logout/authentication resources */

import {API_URLS, APP_URLS} from "./urls";

 export function login(email: string, password: string, appContext: any) {

    const { setUserIsAuthenticated, setUserName, setUserEmail, setUserID } = appContext;

    return fetch(API_URLS.login, {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({email: email, password: password})
    }).then( res => Promise.all([res.status, res.json()])).then(res => {
        let status = res[0]
        let data = res[1]

        if (status === 200) {
            setUserIsAuthenticated(true)
            setUserID(data.user_id)
            setUserName(data.name)
            setUserEmail(data.email)
        }

        return {status, data}
    });
}

function logoutCleanup(appContext: any) {
    /* Perform front-end portion of logout */
    const { setUserIsAuthenticated, setUserName, setUserEmail, setUserID} = appContext;

    // Cleanup context variables
    setUserIsAuthenticated(false)
    setUserID(undefined)
    setUserName(undefined)
    setUserEmail(undefined)
}

export function logout(appContext: any, logoutRedirect=APP_URLS.login) {
    // Run local logout
    logoutCleanup(appContext);

    // Call logout API - use string (action_type)
    fetch(API_URLS.logout, {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({})
    })
}
