import * as React from 'react';
import './workflows.css';
import {API_URLS, APP_URLS} from "../../util/urls";
import {useMatch, useNavigate} from "react-router-dom";
import {useAppContext} from "../../util/context";
import {logout} from "../../util/auth";
import {Badge, Button, Card, CloseButton, Col, Container, Dropdown, Row, Table} from 'react-bootstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowUpRightFromSquare, faEllipsisH, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import {TopNavbar} from "../../components/navbar/navbar";
import {ClientRapView} from "../clients/clients";
import {ProgressBar, ProgressBarStep} from "./components/progress-bar/progress-bar";
import {DocPDFView} from "../sandbox/sandbox";
import LoadingSplash from "../../components/loading-splash/loading-splash";
import {CA12034WorkflowHandler} from "./ca12034/ca12034";
import {
    ChangeFlagModal,
    completeStart,
    CompleteStepModal,
    DefaultStepComponent,
    InstanceDetailsModal,
    RevertStepModal,
    StepCheckTask,
    StepMetadata,
    WorkflowInstance,
    WorkflowMetadata
} from "./common";
import {CA12034OpsWorkflowHandler} from "./ca12034ops/ca12034ops";
import {CA12034SupDeclWorkflowHandler} from "./ca12034supdecl/ca12034supdecl";


// function WorkflowHistory({wi}: {wi: WorkflowInstance<any> }) {
//     const [showEdits, setShowEdits] = React.useState(true)
//
//
//     return <div className={'workflow-history'}>
//         <h5>History</h5>
//         {/* @ts-ignore */}
//         {wi.history.toReversed().map((h) => <WorkflowHistoryItem wi={wi} h={h} />)}
//         {/* @ts-ignore */}
//         {showEdits && wi.editLogs.toReversed().map((el) => <WorkflowEditItem wi={wi} el={el} />)}
//     </div>
// }




export const WorkflowHandlers = {
  "CA12034": CA12034WorkflowHandler,
  "CA12034OPS": CA12034OpsWorkflowHandler,
  "CA12034SUPDECL": CA12034SupDeclWorkflowHandler,
}

export function WorkflowCard({workflowInstance}: {workflowInstance: WorkflowInstance<any>}) {
    const navigate = useNavigate();

    // @ts-ignore
    const workflow = WorkflowHandlers[workflowInstance.workflow_name];

    return <Card className={'workflow-card'} onClick={()=>navigate(APP_URLS.workflowSingleGenerator(workflow.name, workflowInstance.uuid))}>
        <Card.Body>
            <Card.Title>{workflow.displayName}</Card.Title>
            <Card.Text>
                <div>
                    Current step: {workflowInstance.current_step}
                </div>
            </Card.Text>
        </Card.Body>
    </Card>
}

export function WorkflowAddCard({ onClick }: { onClick: any }) {
    return <Card className={'workflow-card add-card'} onClick={onClick}>
        <Card.Body>
            <FontAwesomeIcon icon={faPlus} />
            <div>New Workflow</div>
        </Card.Body>
    </Card>
}

function WorkflowsPage() {
    const navigate = useNavigate();

    const [workflows, setWorkflows] = React.useState<Array<WorkflowMetadata>>([]);

    const [refreshState, setRefreshState] = React.useState<boolean>(false);
    const refresh = () => setRefreshState(!refreshState);

    React.useEffect(() => {
        fetch(API_URLS.getWorkflowsMetadata, {
            method: 'GET',
        }).then( res => Promise.all([res.status, res.json()])).then(res => {
            let status = res[0]
            let data = res[1]

            if (status === 200) {
                setWorkflows(data.workflows)
            }

            return {status, data}
        });
    }, [refreshState])

    return (<React.Fragment>
            {/* Content */}
            <TopNavbar/>
            <Container className={'clients-main-section table-view-container'}>
                <div className={'table-view-header'}>
                    <h3>Workflows</h3>
                </div>
                <div className={'table-view-table'}>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th># Active</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {workflows.map((w: WorkflowMetadata) => (
                                <tr>
                                    <td onClick={() => navigate(APP_URLS.workflowBulkGenerator(w.name))}>
                                        {w.displayName}
                                    </td>
                                    <td className={'clients-rap-cell'} onClick={() => navigate(APP_URLS.workflowBulkGenerator(w.name))}>
                                        {w.numberActive}
                                    </td>
                                    <td>
                                        <Button variant={'outline-primary'} onClick={() => navigate(APP_URLS.workflowInsightsGenerator(w.name))}>Insights</Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </Container>
        </React.Fragment>
    );
}


export function WorkflowDocView({path, instanceID}: {path: string, instanceID: string}) {
    const [blob, setBlob] = React.useState<Blob|null>(null);

    React.useEffect(() => {
        fetch(API_URLS.getWorkflowFile, {
            method: 'POST',
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({
                instanceID: instanceID,
                path: path,
            })
        }).then( res => Promise.all([res.status, res.blob()])).then(res => {
            let status = res[0];
            let blob = res[1];

            if (status === 200) {
                setBlob(blob)
            }

            return {status, blob}
        });
    }, [path])

    return <div className={"client-rap-view"}>
        <Row className={'rap-view-row'}>
            <Col>{blob && <DocPDFView blob={blob}/>}</Col>
        </Row>
    </div>
}


function WorkflowPage({workflowName, instanceID}: {workflowName: string, instanceID: string|null}) {
    const navigate = useNavigate();
    const appContext = useAppContext();

    const [instances, setInstances] = React.useState<Array<WorkflowInstance<any>>>([]);
    const [steps, setSteps] = React.useState<Array<StepMetadata>>([]);

    const [currentStep, setCurrentStep] = React.useState<string>("");
    const [currentInstanceUUID, setCurrentInstanceUUID] = React.useState<string|null>(null);
    const [currentInstance, setCurrentInstance] = React.useState<WorkflowInstance<any>|null>(null);

    const [filterByBatch, setFilterByBatch] = React.useState<string|null>(null);

    const [showCompleteModal, setShowCompleteModal] = React.useState<boolean>(false);
    const [showRevertModal, setShowRevertModal] = React.useState<boolean>(false);
    const [showFlagModal, setShowFlagModal] = React.useState<boolean>(false);
    const [showRapOverlay, setShowRapOverlay] = React.useState<boolean>(false);
    const [showCasePrintOverlay, setShowCasePrintOverlay] = React.useState<boolean>(false);
    const [showInstanceDetailsModal, setShowInstanceDetailsModal] = React.useState<boolean>(false);

    const [loading, setLoading] = React.useState<boolean>(true);

    const [metadataRefreshState, setMetadataRefreshState] = React.useState<boolean>(false);
    const refreshMetadata = () => setMetadataRefreshState(!metadataRefreshState);

    const [instanceRefreshState, setInstanceRefreshState] = React.useState<boolean>(false);
    const refreshInstance = () => setInstanceRefreshState(!instanceRefreshState);

    function loadCurrentInstance() {
        if (!currentInstanceUUID) {
            setCurrentInstance(null)
            return
        }

        fetch(API_URLS.getWorkflowInstance, {
            method: 'POST',
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({workflowName: workflowName, instanceID: currentInstanceUUID})
        }).then( res => Promise.all([res.status, res.json()])).then(res => {
            let status = res[0]
            let data = res[1]

            if (status === 200) {
                // If not at current step, deselect
                if (data.instance.current_step !== currentStep && currentStep !== "archived") {
                    setCurrentInstanceUUID(null)
                    setCurrentInstance(null)
                }

                setCurrentInstance(data.instance)
            }
            else if (status === 401) {
                logout(appContext)
                navigate(APP_URLS.login)
            } else {
                setCurrentInstance(null)
            }

            return {status, data}
        });
    }

    React.useEffect(() => {
        // Refresh metadata
        fetch(API_URLS.getWorkflowViewData, {
            method: 'POST',
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({workflowName: workflowName, instanceID: instanceID})
        }).then( res => Promise.all([res.status, res.json()])).then(res => {
            let status = res[0]
            let data = res[1]

            setLoading(false)

            if (status === 200) {
                const sortedInstances = data.instances.sort((a: WorkflowInstance<any>, b: WorkflowInstance<any>) => {
                    var textA = a.client.name.firstName.toUpperCase();
                    var textB = b.client.name.firstName.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : (a.uuid > b.uuid ? 1: -1);
                })

                setInstances(sortedInstances)
                setSteps(data.steps)

                if (sortedInstances.length === 1) {
                    setCurrentStep(sortedInstances[0].current_step)
                } else if (!currentStep) {
                    setCurrentStep(data.steps[0].name)
                }
            }
            else if (status === 401) {
                logout(appContext)
                navigate(APP_URLS.login)
            }

            return {status, data}
        });

        // Refresh current instance
        loadCurrentInstance()
    }, [metadataRefreshState])

    React.useEffect(() => {
        loadCurrentInstance()
    }, [instanceRefreshState])

    // Complete refresh
    React.useEffect(() => {
        refreshInstance()
        refreshMetadata()
    }, [workflowName])

    // On step/instance change
    React.useEffect(() => {
        // Close doc views when changing instance/step
        setShowRapOverlay(false)
        setShowCasePrintOverlay(false)

        loadCurrentInstance()
    }, [currentInstanceUUID, currentStep, workflowName])

    // On step change
    React.useEffect(() => {
        if (currentInstanceUUID === null && currentStepInstances.length > 0) {
            setCurrentInstanceUUID(currentStepInstances[0].uuid)
        } else if (currentInstanceUUID === null) {
            setCurrentInstanceUUID(null)
        }
    }, [currentStep, workflowName])

    // @ts-ignore
    const workflowHandler = WorkflowHandlers[workflowName];

    let progressBarSteps = steps.map((s, idx) => {return {
            name: s.displayName,
            checked: instances.length === 1 && steps.map(s=>s.name).indexOf(instances[0].current_step) > idx,
            tasks: s.numInstances,
            flags: s.numFlags,
            icon: workflowHandler.steps[s.name]?.icon,
            onClick: () => {setCurrentStep(s.name); setCurrentInstanceUUID(null); refreshMetadata();},
        } as ProgressBarStep});

    // Add archive step to end
    progressBarSteps.push({
        name: "Archived",
        checked: false,
        tasks: instances.filter(i => i.archived).length,
        flags: instances.filter(i => i.archived && i.flagged).length,
        icon: faTrash,
        onClick: () => {setCurrentStep("archived"); setCurrentInstanceUUID(null);},
    } as ProgressBarStep)

    const currentStepInstances = (
        currentStep === "archived"
            ? instances.filter(i => i.archived)
            : instances.filter(i => i.current_step === currentStep && !i.archived)
    ).filter(i => !filterByBatch || i.batch_label === filterByBatch
    ).sort((a: WorkflowInstance<any>, b: WorkflowInstance<any>) => {
        var textA = a.batch_label.toLowerCase() || "";
        var textB = b.batch_label.toLowerCase() || "";

        // If same batch, sort by name
        if (textA === textB) {
            var nameA = a.client.name.firstName.toLowerCase();
            var nameB = b.client.name.firstName.toLowerCase();

            return (nameA < nameB) ? -1 : (nameA > nameB) ? 1 : (a.uuid > b.uuid ? 1: -1);
        }

        return (textA < textB) ? -1 : 1;
    });

    const currentStepHandler = workflowHandler.steps[currentStep]

    const stepComponent = currentStepHandler?.component || DefaultStepComponent(currentStepHandler?.displayName);

    const allChecked = currentStepHandler?.checkTasks.every((t: StepCheckTask) => currentInstance?.data.checked.includes(t.name));

    // Filtering
    const batchLabels = Array.from(new Set(instances.map((instance) => instance.batch_label)));

    // Loading
    if (loading) {
        return <LoadingSplash/>
    }

    return (<React.Fragment>
            {/* Content */}
            <TopNavbar/>
            <div className={'workflow-title'}>
                {workflowHandler.displayName}{instanceID && currentInstance && ` – ${currentInstance.client.name.firstName} ${currentInstance.client.name.lastName}`}
            </div>
            <div className={'workflow-steps'}>
                <ProgressBar currentIdx={[...steps.map(s => s.name), 'archived'].indexOf(currentStep)} steps={progressBarSteps}/>
            </div>
            <div className={'workflow-container'}>
                {<div className={'workflow-instance-list'}>
                    {!instanceID && <div className={"workflow-instance-list-filter"}>
                        Filter: <Dropdown>
                            <Dropdown.Toggle variant="success" size={'sm'}>
                                {filterByBatch || "All batches"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={()=>{setFilterByBatch(null)}}>(All batches)</Dropdown.Item>
                                {/* @ts-ignore */}
                                {Object.values(batchLabels).map((bl) => <Dropdown.Item onClick={()=>{setFilterByBatch(bl)}}>{bl}</Dropdown.Item>)}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>}
                    {currentStepInstances.map((wi: WorkflowInstance<any>, idx: number) => {
                        return <div
                            className={`workflow-instance-list-item noselect ${wi.uuid === currentInstanceUUID && 'active'}`}
                            onClick={()=>{setCurrentInstanceUUID(wi.uuid)}}
                        >
                            <div className={'instance-labels-div'}>
                                <div>{`${wi.client.name.firstName} ${wi.client.name.lastName}`}</div>
                                {wi.batch_label && <Badge pill className={'batch-badge'}>{wi.batch_label}</Badge>}
                                {wi.flagged && <Badge pill bg={'danger'} className={'batch-badge'}>Needs Review</Badge>}
                            </div>
                            <FontAwesomeIcon
                                icon={faArrowUpRightFromSquare}
                                onClick={() => window.open(APP_URLS.clientRapSheetGenerator(wi.client.uuid), '_blank')}
                            />
                            <FontAwesomeIcon
                                icon={faEllipsisH}
                                onClick={() => {setShowInstanceDetailsModal(true)}}
                            />
                        </div>
                    })}
                    {currentStepInstances.length === 0 && <div className={`workflow-instance-list-item noselect`}>No clients at this step</div>}
                </div>}
                <div className={`workflow-step-workspace ${instanceID ? 'full-width' : ''}`}>

                    {currentInstance && React.createElement(stepComponent, {
                        wi: currentInstance,
                        refresh: refreshInstance,
                        refreshMetadata: refreshMetadata,
                    })}

                    {currentInstance && <div className={'workflow-step-controls'}>
                        {currentStep !== "START" && <Button className={'revert-btn'} variant={'outline-danger'} disabled={currentStep === "START"} onClick={()=>setShowRevertModal(true)}>Revert to Previous Step</Button>}
                        <Button className={'show-rap-btn'} variant={'outline-primary'} onClick={() => {setShowRapOverlay(!showRapOverlay);}}>
                            {showRapOverlay ? "Hide Rap Sheet" : "Show Rap Sheet"}
                        </Button>
                        <Button className={'show-case-print-btn'} variant={'outline-primary'} disabled={!currentInstance.data.case_print_path} onClick={() => {setShowCasePrintOverlay(!showCasePrintOverlay);}}>
                            {showCasePrintOverlay ? "Hide Case Print" : "Show Case Print"}
                        </Button>
                        <Button className={'flag-btn'} variant={'outline-secondary'} onClick={()=> setShowFlagModal(true)}>
                            {currentInstance?.flagged ? "Unflag": "Flag for Review"}
                        </Button>
                        {currentStep !== "END" && <Button className={'complete-btn'} variant={'outline-success'}
                          disabled={!allChecked || currentStep === "END" || currentInstance?.flagged}
                          onClick={()=>{
                              // Validate
                              const {valid, msg} = currentStepHandler.validate ? currentStepHandler.validate(currentInstance) : {valid: true, msg: ""};

                              if (!valid) {
                                  alert(msg);
                                  return
                              }

                              if (currentStep === "START") {
                                  completeStart(currentInstance, refreshMetadata);
                              } else {
                                 setShowCompleteModal(true)
                              }
                              }
                        }>
                            {allChecked ? (currentStep === "START" ? "Start Workflow" : "Complete Step") : "Complete Checklist to Continue"}
                        </Button>}
                    </div>}
                </div>
            </div>
            {currentInstance && showRapOverlay && <div className={`rap-overlay ${showCasePrintOverlay && "left1"}`}>
                <CloseButton onClick={() => setShowRapOverlay(false)} />
                <div className={'rap-overlay-contents'}>
                    <ClientRapView docOnly={true} clientID={currentInstance.client.uuid} />
                </div>
            </div>}
            {currentInstance && showCasePrintOverlay && <div className={'rap-overlay'}>
                <CloseButton onClick={() => setShowCasePrintOverlay(false)} />
                <div className={'rap-overlay-contents'}>
                    {/* TODO Make generic so it will work with other workflows */}
                    <WorkflowDocView path={currentInstance.data.case_print_path} instanceID={currentInstance.uuid} />
                </div>
            </div>}
            {currentInstance && <CompleteStepModal
                instance={currentInstance}
                show={showCompleteModal}
                setShow={setShowCompleteModal}
                onComplete={() => {setCurrentInstanceUUID(null); refreshMetadata();}}
            />}
            {currentInstance && <RevertStepModal
                instance={currentInstance}
                show={showRevertModal}
                setShow={setShowRevertModal}
                onComplete={() => {setCurrentInstanceUUID(null); refreshMetadata();}}
            />}
            {currentInstance && <ChangeFlagModal
                instance={currentInstance}
                show={showFlagModal}
                setShow={setShowFlagModal}
                onComplete={() => refreshMetadata()}
            />}
            {currentInstance && <InstanceDetailsModal
                instance={currentInstance}
                show={showInstanceDetailsModal}
                setShow={setShowInstanceDetailsModal}
                triggerRefresh={() => refreshMetadata()}
            />}
        </React.Fragment>
    );
}


export function WorkflowsPageHandler() {
    // Get URL params
    const matchWithInstance = useMatch(`${APP_URLS.workflows}/:workflowName/:instanceID`)
    const matchWithWorkflow = useMatch(`${APP_URLS.workflows}/:workflowName`);
    const matchBase = useMatch(`${APP_URLS.workflows}`);
    const match = matchWithInstance || matchWithWorkflow || matchBase

    //@ts-ignore
    const workflowName = match?.params.workflowName || "";
    //@ts-ignore
    const instanceID = match?.params.instanceID || null;

    if (workflowName) {
        return <WorkflowPage workflowName={workflowName} instanceID={instanceID}/>
    }

    return <WorkflowsPage />
}
