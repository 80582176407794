import * as React from 'react';
import Main from './pages/main';
import './app.css';
import {AppContext} from "./util/context";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import {APP_URLS} from "./util/urls";

function AppContextProvider({ children }: {children: any}) {
    const [userIsAuthenticated, setUserIsAuthenticated] = React.useState(false);
    const [userID, setUserID] = React.useState("");
    const [userName, setUserName] = React.useState("");
    const [userEmail, setUserEmail] = React.useState("");

    let contextArgs = {
        userIsAuthenticated, setUserIsAuthenticated,
        userID, setUserID,
        userName, setUserName,
        userEmail, setUserEmail
    }

    return <AppContext.Provider value={contextArgs}>
        {children}
    </AppContext.Provider>
}

function App() {
    return (
        <AppContextProvider>
            <div className="App">
                <Main />
            </div>
        </AppContextProvider>
    );
}

export default App;
