import * as React from "react";
import './progress-bar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCheck} from '@fortawesome/free-solid-svg-icons';

export type ProgressBarStep = {
    name: string,
    checked?: boolean,
    tasks?: number,
    flags?: number,
    icon: any,
    onClick: () => void
    hide?: boolean
}

export type ProgressBarProps = {
    steps: Array<ProgressBarStep>,
    currentIdx: number
};

export function ProgressBar({steps, currentIdx}: ProgressBarProps) {

    return <div className={`workflow-progress-bar`}>
        <div className="workflow-progress-bar-strike" />
        {steps.map((step, idx) => ( step.hide ? <React.Fragment/> :
            <div className={`workflow-progress-bar-item ${idx === currentIdx ? "active" : ""}`} onClick={step.onClick}>
                <div className={'workflow-progress-bar-circle'}>
                    {
                        step.checked && <div className={'workflow-progress-bar-status-circle completed'}><FontAwesomeIcon icon={faCheck}/></div>
                    }
                    {
                        (step.flags && step.flags > 0) ? <div className={'workflow-progress-bar-status-circle left flags'}>{step.flags}</div> : <React.Fragment/>
                    }
                    {
                        (step.tasks && step.tasks > 0) ? <div className={'workflow-progress-bar-status-circle tasks-remaining'}>{step.tasks}</div> : <React.Fragment/>
                    }
                    <FontAwesomeIcon icon={step.icon} className={'progress-circle-icon'}/>
                </div>
                <div className={'workflow-progress-bar-label noselect'}>{step.name}</div>
            </div>
        ))}
    </div>
}
