import {CourtDate, ExtractedCase, ExtractedComment, ExtractedCount, RapSheetExtractedData} from "../../clients/clients";
import {Badge, Button, Card, CloseButton, Container, Form, Modal, Table} from "react-bootstrap";
import * as React from "react";
import {API_URLS} from "../../../util/urls";
import './ca12034ops.css';

import {
    faCheck,
    faComment,
    faEye,
    faFolder,
    faGavel,
    faLandmark,
    faPaperPlane,
    faPerson,
    faPersonCircleCheck,
    faPlus,
    faScaleBalanced,
    faTrash
} from "@fortawesome/free-solid-svg-icons";
import {DynamicField, DynamicFieldProps} from "../../declaration/declaration";
import {WorkflowDocView, WorkflowHandlers} from "../workflows";
import {
    completeStart, parseDate,
    StepChecklist,
    StepComponentProps,
    WorkflowEditItem,
    WorkflowEditLog, WorkflowHandler,
    WorkflowHistoryItem,
    WorkflowInstance,
    WorkflowStateChange
} from "../common";

type CA12034OpsData = {

}

function StartStep({wi, refresh, refreshMetadata}: StepComponentProps) {
    // @ts-ignore
    const workflowHandler = WorkflowHandlers[wi.workflow_name];

    return <Container className={'step-container'}>
        <div className={'step-main-section'}>
            <h1>{wi.client.name.firstName} {wi.client.name.lastName}</h1>
            <h2>{workflowHandler.displayName}</h2>
            <h4>Created {(parseDate(wi.created_at)).toLocaleDateString("en", {timeZone: 'UTC'})}</h4>
            <br/>
            <Button variant={'success'} onClick={() => completeStart(wi, refreshMetadata)}>Start Workflow</Button>
        </div>
    </Container>
}


function ArchivedStep({wi, refresh}: StepComponentProps) {
    // @ts-ignore
    return <Container className={'step-container'} fluid>
        <div className={'step-main-section'}>
        </div>
        <div className={'workflow-checkist-bar'}>
            TODO
        </div>

    </Container>
}

export const CA12034OpsWorkflowHandler: WorkflowHandler = {
    name: "CA12034OPS",
    displayName: "CA 1203.4 Operations",
    steps: {
        START: {
            sortIdx: 0,
            name: "START",
            displayName: "Created",
            icon: faPlus,
            // @ts-ignore
            component: StartStep,
            checkTasks: []
        },
        PACKET: {
            sortIdx: 1,
            name: "PACKET",
            displayName: "Packet",
            icon: faEye,
            checkTasks: []
        },
        END: {
            sortIdx: 2,
            name: "END",
            displayName: "Finished",
            icon: faCheck,
            checkTasks: []
        },
        archived: {
            sortIdx: 3,
            name: "archived",
            displayName: "Archived",
            icon: faTrash,
            checkTasks: [],
            // @ts-ignore
            component: ArchivedStep,

        },
    }
}