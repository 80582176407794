import * as React from 'react';
import './clients.css';
import {Container, Form, Row, Col, Badge, Button, Dropdown, Table, Modal, ProgressBar, Card} from 'react-bootstrap';
import {API_URLS, APP_URLS} from "../../util/urls";
import {TopNavbar} from "../../components/navbar/navbar";
import {useNavigate, useMatch} from "react-router-dom";
import {DocPDFView} from "../sandbox/sandbox";
import ReactJson from "react-json-view";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPlus} from '@fortawesome/free-solid-svg-icons';
import {WorkflowAddCard, WorkflowCard, WorkflowHandlers} from "../workflows/workflows";
import {logout} from "../../util/auth";
import {useAppContext} from "../../util/context";
import LoadingSplash from "../../components/loading-splash/loading-splash";
import {WorkflowInstance} from "../workflows/common";


type RapSheet = {
    uuid: string,
    client_uuid: string,
    state: string,
    date_uploaded: string,
    last_extraction_date: string|null,
}

export type Client = {
    uuid: string,
    email: string|null,
    dateOfBirth: string|null,
    name: {
        firstName: string,
        middleName: string,
        lastName: string,
    },
    latestRapSheet: RapSheet|null,
    workflows: Array<WorkflowInstance<any>>
}

export type ExtractedComment = {
    text: string,
    date: string|null,
}

export type ExtractedSentence = ExtractedComment & {}

export type ExtractedDisposition = ExtractedComment & {}

export type ExtractedCount = {
    count_number: string|null,
    warrant_number: string|null,
    case_number: string|null,
    record_id: string|null,
    state_control_number: string|null,
    document_control_number: string|null,
    arrest_date: string|null,
    arrest_by: string|null,
    arrest_address: string|null,
    type_of_crime: string|null,
    charge_name: string|null,
    statute: string|null,
    code: string|null,
    attempted: boolean,
    secondary_charge_name: string|null,
    secondary_statute: string|null,
    secondary_code: string|null,
    charge_in_comment: boolean,
    conviction_type: string|null,
    latest_sentence: ExtractedSentence|null
    sentences: Array<ExtractedSentence>,
    dispositions: Array<ExtractedDisposition>,
    comments: Array<ExtractedComment>,
    marked_as_parole_violation: boolean,
    marked_as_probation_violation: boolean,
    dismissed: boolean,
    dismissed_on: string|null,
    convicted: boolean,
    convicted_on: string|null,
    misc_flags: {[key: string]: boolean}
}

export type CourtDate = {
    court: string,
    date: string|null,
}


export type ExtractedCase = {
    uuid: string,
    name_number_used: number,
    arrest_date: string,
    arrest_agency: string,
    counts: {[key: string]: ExtractedCount},
    case_level_comments: Array<ExtractedComment>,
    case_level_sentences: Array<ExtractedSentence>,
    case_level_dispositions: Array<ExtractedDisposition>,
    court_dates: Array<CourtDate>,
}

export type RapSheetExtractedData = {
    name: string,
    names: any,
    ssn: string,
    ssns: any,
    dob: string,
    misc_personal_info: any,
    cases: Array<ExtractedCase>,
    custodies: any,
}






// TODO work on missing names

// TODO work on duplicate uploads
// TODO add global errors
// TODO add global messages




function AddClientsFromRapSheetsModal({show, setShow, onUpload}: {show: boolean, setShow: any, onUpload: any}) {
    const [files, setFiles] = React.useState<Array<File>>([]);
    const [showProgressBar, setShowProgressBar] = React.useState<boolean>(false);
    const [progressBarMax, setProgressBarMax] = React.useState<number>(0);
    const [progressBarCurrent, setProgressBarCurrent] = React.useState<number>(0);
    const [batchLabel, setBatchLabel] = React.useState<string>("");
    const [skippedExisting, setSkippedExisting] = React.useState<Client[]>([]);
    const [done, setDone] = React.useState<boolean>(false);

    const skippedExistingRef = React.useRef([] as Client[]);
    skippedExistingRef.current = skippedExisting


    const handleFileChange = (e: any) => {
        const selectedFiles = Array.from(e.target.files) as Array<File>;
        setFiles(selectedFiles);
    };

    // chunk by 10s and show progress bar
    const handleSubmit = async (e: any) => {
        e.preventDefault();
        e.stopPropagation();

        // Show progress bar
        setShowProgressBar(true);
        setProgressBarMax(files.length)
        setProgressBarCurrent(0)

        // Chunk by 10s for upload
        const chunkSize = 25;

        let uploadsSucceeded = 0;

        for (let i = 0; i < files.length; i += chunkSize) {
            const chunk = files.slice(i, i + chunkSize);

            // Add files to form data
            const data = new FormData();
            for (const file of chunk) {
                data.append('files[]', file, file.name);
            }

            data.append('batchLabel', batchLabel);


            // Make request
            await fetch(API_URLS.addClientsFromRapSheets, {
                method: 'POST',
                body: data
            }).then( res => Promise.all([res.status, res.json()])).then(res => {
                let status = res[0]
                let data = res[1]

                if (status === 200) {
                    uploadsSucceeded += data.newClients.length + data.skippedExistingClients.length
                    setSkippedExisting(skippedExistingRef.current.concat(data.skippedExistingClients))
                    setProgressBarCurrent(uploadsSucceeded)
                }
            })
        }


        // Reset
        setFiles([])
        setDone(true)
        // setShow(false)
        // setShowProgressBar(false);
        // setProgressBarMax(0)
        // setProgressBarCurrent(0)
        // setBatchLabel("")

        // Callback
        onUpload(uploadsSucceeded)
    }

    return (
        <Modal  show={show} onHide={()=>{
            setShow(false);
            setShowProgressBar(false);
            setProgressBarMax(0)
            setProgressBarCurrent(0)
            setSkippedExisting([])
        }} >
            <Modal.Header closeButton >
                <Modal.Title>Add Clients from Rap Sheets</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Label>Batch Label</Form.Label>
                    <Form.Control type="text" placeholder={'Batch label'} name="batchLabel"
                        value={batchLabel} onChange={(e: any)=>{setBatchLabel(e.target.value)}}
                    />
                    <br/>
                    <Form.Label>Rap Sheets</Form.Label>
                    <Form.Control type="file" multiple onChange={handleFileChange} />
                    <br/>
                    <Button type="submit" variant="primary" disabled={!(files && files.length > 0) || showProgressBar}>
                        Add Clients
                    </Button>
                    {showProgressBar && <><br/><br/><ProgressBar variant="success" min={0} now={progressBarCurrent} max={progressBarMax} animated={true} striped={true} label={`${progressBarCurrent}/${progressBarMax}`}/></>}
                    {done && <><br/><h3>Upload Complete!</h3></>}
                    {skippedExisting.length > 0 && <>
                        <br/>
                        <Form.Label>Skipped existing clients:</Form.Label>
                        {skippedExisting.map((c) => <div>{c.name.firstName} {c.name.lastName}</div>)}
                    </>}
                </Form>
            </Modal.Body>
        </Modal>
    )
}

function AddClientModal({show, setShow, onUpload}: {show: boolean, setShow: any, onUpload: any}) {
    const [firstName, setFirstName] = React.useState("")
    const [middleName, setMiddleName] = React.useState("")
    const [lastName, setLastName] = React.useState("")
    const [email, setEmail] = React.useState("")
    const [emailError, setEmailError] = React.useState("");

    const validateEmail = (email: string) => {
        // Simple email regex validation
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        e.stopPropagation();

        // Make request
        fetch(API_URLS.addClient, {
            method: 'POST',
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({
                firstName: firstName,
                middleName: middleName,
                lastName: lastName,
                email: email,
            })
        }).then( res => Promise.all([res.status, res.json()])).then(res => {
            let status = res[0]
            let data = res[1]

            if (status === 200) {
                // Reset
                setFirstName("")
                setMiddleName("")
                setLastName("")
                setEmail("")
                setEmailError("")

                // Callback
                onUpload(data)
            }
        })

    }

    return (
        <Modal  show={show} onHide={()=>{
            setShow(false);
        }} >
            <Modal.Header closeButton >
                <Modal.Title>Add Client (without rap sheet)</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder={'First name'}
                        name="firstName"
                        required
                        autoComplete="disabled"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                    />
                    <br/>
                    <Form.Label>Middle Name</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder={'Middle name'}
                        name="middleName"
                        autoComplete="disabled"
                        value={middleName}
                        onChange={(e) => setMiddleName(e.target.value)}
                    />
                    <br/>
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder={'Last name'}
                        name="lastName"
                        required
                        autoComplete="disabled"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                    />
                    <br/>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                        type={"email"}
                        placeholder="Email"
                        autoComplete="disabled"
                        value={email}
                        onChange={(e) => {setEmail(e.target.value); setEmailError("");}}
                        onBlur={() => {
                            if (!validateEmail(email)) {
                                setEmailError('Please enter a valid email');
                            } else {
                                setEmailError('');
                            }
                        }}
                    />
                    {emailError && <p style={{ color: 'red' }}>{emailError}</p>}
                    <br/>

                    <Button type="submit" variant="primary">
                        Add Client
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

function AddClientRapSheetsModal({client, show, setShow, onUpload}: {client: Client, show: boolean, setShow: any, onUpload: any}) {
    const [file, setFile] = React.useState<File|null>(null);

    const handleFileChange = (e: any) => {
        setFile(e.target.files[0] as File);
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        e.stopPropagation();

        if (!file) {
            return
        }

        // Add file to form data
        const data = new FormData();
        data.append('file', file, file.name);
        data.append('clientID', client.uuid);

        // Make request
        fetch(API_URLS.addClientRapSheet, {
            method: 'POST',
            body: data
        }).then( res => Promise.all([res.status, res.json()])).then(res => {
            let status = res[0]
            let data = res[1]

            if (status === 200) {
                // Reset
                setFile(null)
                setShow(false)

                // Callback
                onUpload()
            }
        })
    }

    return (
        <Modal  show={show} onHide={()=>{setShow(false)}} >
            <Modal.Header closeButton >
                <Modal.Title>
                    Upload Rap Sheet for&nbsp;
                    {client && client.name.firstName ? <>{client.name.firstName} {client.name.middleName}{client.name.middleName ? ' ' : ''}{client.name.lastName}</> : "Unnamed Client"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Control type="file" onChange={handleFileChange} />
                    <br/>
                    <Button type="submit" variant="primary" disabled={!file}>
                        Upload
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

function GenerateRapSheetRequestModal({client, show, setShow}: {client: Client, show: boolean, setShow: any}) {
    const [jurisdiction, setJurisdiction] = React.useState<string>("");
    const [caseNumber, setCaseNumber] = React.useState<string>("");

    const handleSubmit = (e: any) => {
        e.preventDefault();
        e.stopPropagation();

        // Make request
        fetch(API_URLS.generateRapSheetRequest, {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                clientID: client.uuid,
                jurisdiction: jurisdiction,
                caseNumber: caseNumber,
            })
        }).then( res => Promise.all([res.status, res.blob()])).then(res => {
            let status = res[0];
            let blob = res[1];

            if (status === 200) {
                // Download
                let csvURL = window.URL.createObjectURL(blob);
                let tempLink = document.createElement('a');
                tempLink.href = csvURL;
                tempLink.setAttribute('download', `${client.name.firstName} ${client.name.lastName} Rap Request.pdf`);
                tempLink.click();

                // Reset
                setJurisdiction("")
                setCaseNumber("")
                setShow(false)
            }

            return {status, blob}
        });
    }

    return (
        <Modal  show={show} onHide={()=>{setShow(false)}} >
            <Modal.Header closeButton >
                <Modal.Title>
                    Generate Rap Request Form for&nbsp;
                    {client && client.name.firstName ? <>{client.name.firstName} {client.name.middleName}{client.name.middleName ? ' ' : ''}{client.name.lastName}</> : "Unnamed Client"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Label>Case Number</Form.Label>
                    <Form.Control
                        required type="text" placeholder={'Case Number'} name="casenumber"
                        value={caseNumber} onChange={(e: any)=>{setCaseNumber(e.target.value)}}
                    />
                    <br/>
                    <Form.Label>Jurisdiction</Form.Label>
                    <Form.Control
                        required type="text" placeholder={'Jurisdiction (e.g. Los Angeles County)'} name="jurisdiction"
                        value={jurisdiction} onChange={(e: any)=>{setJurisdiction(e.target.value)}}
                    />
                    <br/>
                    <Button type="submit" variant="primary" disabled={!jurisdiction || !caseNumber}>
                        Generate
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    )
}


function AddWorkflowModal({client, show, setShow}: {client: Client, show: boolean, setShow: any}) {
    const navigate = useNavigate();

    const [workflowName, setWorkflowName] = React.useState<string>(Object.keys(WorkflowHandlers)[0]);

    const handleSubmit = (e: any) => {
        e.preventDefault();
        e.stopPropagation();

        // Make request
        fetch(API_URLS.createWorkflowInstance, {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                clientID: client.uuid,
                workflowName: workflowName,
            })
        }).then( res => Promise.all([res.status, res.json()])).then(res => {
            let status = res[0];
            let data = res[1];

            if (status === 200) {
                // Reset
                setShow(false)
                setWorkflowName(Object.keys(WorkflowHandlers)[0])

                const wi = data.workflowInstance as WorkflowInstance<any>;

                // Redirect
                navigate(APP_URLS.workflowSingleGenerator(wi.workflow_name, wi.uuid))
            }

            return {status, data}
        });
    }

    return (
        <Modal  show={show} onHide={()=>{setShow(false)}} >
            <Modal.Header closeButton >
                <Modal.Title>
                    Create Workflow for&nbsp;
                    {client && client.name.firstName ? <>{client.name.firstName} {client.name.middleName}{client.name.middleName ? ' ' : ''}{client.name.lastName}</> : "Unnamed Client"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Select aria-label="Default select example" value={workflowName} onChange={(e)=>{setWorkflowName(e.target.value)}}>
                    {/* @ts-ignore */}
                    {Object.keys(WorkflowHandlers).map((k: string) => <option value={k}>{WorkflowHandlers[k].displayName}</option>)}
                </Form.Select >
                <br/>
                <Form onSubmit={handleSubmit}>
                    <Button type="submit" variant="primary" disabled={false}>
                        Create Workflow
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    )
}



function ClientsPage() {
    const navigate = useNavigate();
    const appContext = useAppContext();

    const [clients, setClients] = React.useState<Array<Client>>([]);
    const [showAddFromRapModal, setShowAddFromRapModal] = React.useState<boolean>(false);
    const [showAddModal, setShowAddModal] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(true);

    const [refreshState, setRefreshState] = React.useState<boolean>(false);
    const refresh = () => setRefreshState(!refreshState);

    React.useEffect(() => {
        fetch(API_URLS.getClients, {
            method: 'GET',
        }).then( res => Promise.all([res.status, res.json()])).then(res => {
            let status = res[0]
            let data = res[1]

            setLoading(false)

            if (status === 200) {
                setClients(data.clients)
            }
            else if (status === 401) {
                logout(appContext)
                navigate(APP_URLS.login)
            }

            return {status, data}
        });
    }, [refreshState])

    // Loading
    if (loading) {
        return <LoadingSplash/>
    }

    return (<React.Fragment>
            {/* Content */}
            <TopNavbar/>
            <Container className={'clients-main-section table-view-container'}>
                <div className={'table-view-header'}>
                    <h3>Clients</h3>
                    <Dropdown>
                        <Dropdown.Toggle variant="success">
                            Actions
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={()=>setShowAddFromRapModal(true)}>Add Clients from Rap Sheets</Dropdown.Item>
                            <Dropdown.Item onClick={()=>setShowAddModal(true)}>Add Client without Rap Sheet</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <div className={'table-view-table'}>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Rap Sheet</th>
                                <th>Workflows</th>
                            </tr>
                        </thead>
                        <tbody>
                            {clients.sort((a: Client, b: Client) => {
                                    var textA = `${a.name.firstName.toUpperCase()}${a.name.middleName.toUpperCase()}${a.name.lastName.toUpperCase()}`;
                                    var textB = `${b.name.firstName.toUpperCase()}${b.name.middleName.toUpperCase()}${b.name.lastName.toUpperCase()}`;

                                    if (textA === ""){
                                        return 1
                                    }

                                    if (textB === ""){
                                        return -1
                                    }

                                    return (textA < textB) ? -1 : (textA > textB) ? 1 : (a.uuid > b.uuid ? 1: -1);
                                }).map((c: Client) => (
                                <tr>
                                    <td onClick={() => navigate(APP_URLS.clientGenerator(c.uuid))}>
                                        {c.name.firstName} {c.name.middleName}{c.name.middleName ? ' ' : ''}{c.name.lastName}
                                    </td>
                                    <td className={'clients-rap-cell'} onClick={() => navigate(APP_URLS.clientGenerator(c.uuid))}>
                                        {
                                            c.latestRapSheet ?
                                                <Badge>
                                                    Latest: {(new Date(c.latestRapSheet.date_uploaded).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric',
        timeZone: 'UTC', }))}
                                                </Badge>
                                                :
                                                <Badge bg={'danger'}>
                                                    No Rap Sheet
                                                </Badge>
                                        }
                                    </td>
                                    <td>
                                        {c.workflows.map((wi) => {
                                            let variant = "info"
                                            if (wi.current_step === "START"){
                                                variant = "danger"
                                            }
                                            else if (wi.current_step === "END"){
                                                variant = "success"
                                            }
                                            return <Badge bg={variant}>{wi.workflow_name}: {wi.current_step}{wi.batch_label ? ` - ${wi.batch_label}` : ""}</Badge>
                                        })}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </Container>

            {/* Modals */}
            <AddClientsFromRapSheetsModal
                show={showAddFromRapModal}
                setShow={setShowAddFromRapModal}
                onUpload={(numNew: number) => refresh()}
            />
            <AddClientModal
                show={showAddModal}
                setShow={setShowAddModal}
                onUpload={(newClient: Client) => {
                    navigate(APP_URLS.clientGenerator(newClient.uuid))
                }}
            />
        </React.Fragment>
    );
}

export function ClientRapView({clientID, docOnly}: {clientID: string, docOnly?: boolean}) {
    const [pdfBlob, setPdfBlob] = React.useState<Blob|null>(null);
    const [extractedData, setExtractedData] = React.useState<any>(null);

    React.useEffect(() => {
        fetch(API_URLS.getClientRapSheetFile, {
            method: 'POST',
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({clientID: clientID})
        }).then( res => Promise.all([res.status, res.blob()])).then(res => {
            let status = res[0];
            let blob = res[1];

            if (status === 200) {
                setPdfBlob(blob)
            }

            return {status, blob}
        });
    }, [clientID])

    React.useEffect(() => {
        fetch(API_URLS.getClientRapSheetData, {
            method: 'POST',
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({clientID: clientID})
        }).then( res => Promise.all([res.status, res.json()])).then(res => {
            let status = res[0];
            let data = res[1];

            if (status === 200) {
                setExtractedData(data)
            }

            return {status, data}
        });
    }, [clientID])

    return <div className={"client-rap-view"}>
        {!docOnly && <h4>Rap Sheet</h4>}
        <Row className={'rap-view-row'}>
            <Col>{pdfBlob && <DocPDFView blob={pdfBlob}/>}</Col>
            {!docOnly && <Col>
                <div className={'doc-text-view'}>
                    <ReactJson
                        src={extractedData || {}}
                        displayObjectSize={false}
                        displayDataTypes={false}
                        indentWidth={3}
                        style={{fontSize: "12px"}}
                    />
                </div>
            </Col>}
        </Row>
    </div>
}

function IndividualClientPage({clientID, view}: {clientID: string, view?: string}) {
    const navigate = useNavigate();

    const [client, setClient] = React.useState<Client|null>(null);
    const [workflows, setWorkflows] = React.useState<Array<WorkflowInstance<any>>|null>(null);
    const [showAddRapModal, setShowAddRapModal] = React.useState<boolean>(false);
    const [showRapRequestModal, setShowRapRequestModal] = React.useState<boolean>(false);
    const [showAddWorkflowModal, setShowAddWorkflowModal] = React.useState<boolean>(false);

    const [refreshState, setRefreshState] = React.useState<boolean>(false);
    const refresh = () => setRefreshState(!refreshState);

    React.useEffect(() => {
        fetch(API_URLS.getClient, {
            method: 'POST',
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({clientID: clientID})
        }).then( res => Promise.all([res.status, res.json()])).then(res => {
            let status = res[0]
            let data = res[1]

            if (status === 200) {
                setClient(data.client)
            }

            return {status, data}
        });
    }, [refreshState])

    React.useEffect(() => {
        fetch(API_URLS.getClientWorkflows, {
            method: 'POST',
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({clientID: clientID})
        }).then( res => Promise.all([res.status, res.json()])).then(res => {
            let status = res[0]
            let data = res[1]

            if (status === 200) {
                setWorkflows(data.workflowInstances)
            }

            return {status, data}
        });
    }, [refreshState])


    return (<React.Fragment>
            {/* Content */}
            <TopNavbar/>
            <Container fluid className={'clients-main-section table-view-container'}>
                <div className={'client-info'}>
                    <div className={'client-info-header'}>
                        <div className={'client-name'}>{client && client.name.firstName ? <h3>{client.name.firstName} {client.name.middleName}{client.name.middleName ? ' ' : ''}{client.name.lastName}</h3> : <h3>Unnamed Client</h3>}</div>
                        <Dropdown>
                            <Dropdown.Toggle variant="success">
                                Actions
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item disabled={!client?.latestRapSheet} href={APP_URLS.clientRapSheetGenerator(client?.uuid || "")}>View Rap Sheet</Dropdown.Item>
                                <Dropdown.Item onClick={()=>{setShowAddRapModal(true)}}>Update Rap Sheet</Dropdown.Item>
                                <Dropdown.Item onClick={()=>{setShowRapRequestModal(true)}}>Generate Rap Sheet Request</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <Table bordered>
                        <tbody>
                            <tr>
                                <td>Email</td>
                                <td>{client?.email}</td>
                            </tr>
                            <tr>
                                <td>Date of Birth</td>
                                <td>{client?.dateOfBirth}</td>
                            </tr>
                            <tr>
                                <td>Latest Rap Sheet</td>
                                <td>
                                    {
                                        client?.latestRapSheet ?
                                            <Badge>
                                                Uploaded: {(new Date(client.latestRapSheet.date_uploaded).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric',
        timeZone: 'UTC', }))}
                                            </Badge>
                                            :
                                            <Badge bg={'danger'}>
                                                No Rap Sheet
                                            </Badge>
                                    }
                                    {
                                        client?.latestRapSheet && client.latestRapSheet.last_extraction_date?
                                            <Badge>
                                                Extracted: {(new Date(client.latestRapSheet.last_extraction_date).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric',
        timeZone: 'UTC', }))}
                                            </Badge>
                                            :
                                            <Badge bg={'danger'}>
                                                Never Extracted
                                            </Badge>
                                    }
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
                <div className={'client-workflows'}>
                    <h3>Workflows</h3>
                    <div className={'workflows-container'}>
                        {workflows?.map((wi: WorkflowInstance<any>) => <WorkflowCard workflowInstance={wi}/>)}
                        <WorkflowAddCard onClick={()=>{setShowAddWorkflowModal(true)}}/>
                    </div>
                </div>

                {view === "rap" && <div className={"client-view-section"}>
                    <ClientRapView clientID={clientID} />
                </div>}
            </Container>

            {/* Modals */}
            {client && <AddClientRapSheetsModal client={client} show={showAddRapModal} setShow={setShowAddRapModal} onUpload={()=>{refresh(); navigate(APP_URLS.clientRapSheetGenerator(client?.uuid || ""))}}/>}
            {client && <GenerateRapSheetRequestModal client={client} show={showRapRequestModal} setShow={setShowRapRequestModal} />}
            {client && <AddWorkflowModal client={client} show={showAddWorkflowModal} setShow={setShowAddWorkflowModal} />}
        </React.Fragment>
    );
}

function ClientsPageHandler() {
    // Get URL params
    const matchWithClientView = useMatch(`${APP_URLS.clients}/:clientID/:clientView`)
    const matchWithClient = useMatch(`${APP_URLS.clients}/:clientID`);
    const matchBase = useMatch(`${APP_URLS.clients}`);
    const match = matchWithClientView || matchWithClient || matchBase

    //@ts-ignore
    const clientID = match?.params.clientID || "";
    //@ts-ignore
    const clientView = match?.params.clientView || "";

    if (clientID) {
        return <IndividualClientPage clientID={clientID} view={clientView}/>
    }

    return <ClientsPage />
}


export default ClientsPageHandler;
