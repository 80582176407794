import React from 'react';
import {Navigate, useLocation} from "react-router-dom";
import {API_URLS, APP_URLS} from "../util/urls"
import {useAppContext} from "./context";
import LoadingSplash from "../components/loading-splash/loading-splash";

interface PrivateRouteProps {
    component: React.ComponentType
}

const defaultPrivateRouteProps: PrivateRouteProps = {
    component: React.Fragment,
}


const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: RouteComponent }) => {
    const appContext = useAppContext();
    const location = useLocation();
    const {setUserIsAuthenticated} = appContext;

    const [loading, setLoading] = React.useState(true)


    React.useEffect(() => {
        fetch(API_URLS.testAccessToken, {
            method: 'POST',
            headers: { "Content-Type": "application/json"}
        }).then( res => Promise.all([res.status, res.json()])).then(res => {
            let status = res[0]
            let data = res[1]

            if (status === 200) {
                setUserIsAuthenticated(true);
            } else {
                setUserIsAuthenticated(false);
            }

            setLoading(false);
        })
    }, [location]);  // Make sure effect always run on route change

    const { userIsAuthenticated } = appContext;

    if (loading) {
        return <LoadingSplash/>
    } else if (userIsAuthenticated) {
        return <RouteComponent />
    } else {
        return <Navigate to={APP_URLS.login} state={{redirect: location.pathname}} />
    }
}

PrivateRoute.defaultProps = defaultPrivateRouteProps;

export default PrivateRoute
