import {CourtDate, ExtractedCase, ExtractedComment, ExtractedCount, RapSheetExtractedData} from "../../clients/clients";
import {Badge, Button, Card, CloseButton, Container, Form, Modal, Table, Row, Col} from "react-bootstrap";
import * as React from "react";
import {API_URLS, APP_URLS} from "../../../util/urls";
import DatePicker from "react-datepicker";
import './ca12034.css';


import {
    faCheck,
    faComment,
    faEye,
    faFolder,
    faGavel,
    faLandmark,
    faPaperPlane,
    faPerson,
    faPersonCircleCheck,
    faPlus,
    faScaleBalanced,
    faTrash,
    faRocket
} from "@fortawesome/free-solid-svg-icons";
import {DynamicField, DynamicFieldProps} from "../../declaration/declaration";
import {WorkflowDocView, WorkflowHandlers} from "../workflows";
import {
    completeStart,
    dateFromMMDDYYYY,
    dateToMMDDYYYY,
    formatDate,
    formatDateObj,
    parseDate,
    StepChecklist,
    StepComponentProps,
    WorkflowEditItem,
    WorkflowEditLog,
    WorkflowHandler,
    WorkflowHistoryItem,
    WorkflowInstance,
    WorkflowStateChange
} from "../common";
import {useNavigate} from "react-router-dom";

type CA12034Data = {
    case_uuid: string | null,
    count_number: string | null,
    case_number: string | null,
    case_print_path: string | null,
    conviction_date: string | null,
    dismissal_date: string | null,
    statute: string | null,
    code: string | null,
    charge_name: string | null,
    conviction_type: string | null,
    relief_type: string | null,
    hearing_court: string | null,
    probation_violations: boolean | null,
    remedy: string | null,
    court: string | null,
    cr180_checks: Array<string> | null,
    petition_path: string | null,
    order_path: string | null,
    declaration_path: string | null,
    declaration_required: boolean | null,
    declaration_draft_path: string | null,
    declaration_draft_drive_id: string | null,
    proof_of_service_path: string | null,
    service_cover_path: string | null,
    county_specific_path: string | null,
    service_id: number | null,
    service_method: string | null,
    // service_date: string | null,
    service_use_backup_fax: boolean,
    declaration_generation_answers: object | null,
    declaration_interview_answers: object | null,
    // service_by_name: string | null,
    // service_by_city: string,
    // service_by_state: string,
    drivers_number: string | null,
}

export function WorkflowHistory({wi}: { wi: WorkflowInstance<any> }) {
    const [showEdits, setShowEdits] = React.useState(true);

    // Combine history and edit logs
    const combinedLogs = [
        ...wi.history.map(h => ({...h, type: 'history'})),
        ...showEdits ? wi.editLogs.map(el => ({...el, type: 'edit'})) : []
    ];

    // Sort the combined logs by timestamp
    // @ts-ignore
    combinedLogs.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));

    return (
        <div className="workflow-history">
            <h5>History</h5>
            {combinedLogs.length === 0 && "No history yet"}
            { /* @ts-ignore */}
            {combinedLogs.map((log, index) => log.from_step
                ? <WorkflowHistoryItem key={index} wi={wi} h={log as WorkflowStateChange}/>
                : <WorkflowEditItem key={index} wi={wi} el={log as WorkflowEditLog}/>
            )}
        </div>
    );
}

function CA12034DataView({wi}: { wi: WorkflowInstance<any> }) {
    const [das, setDas] = React.useState<{ [k: number]: DAContact }>({})

    React.useEffect(() => {
        fetch(API_URLS.getDADirectory, {
            method: 'GET',
        }).then(res => Promise.all([res.status, res.json()])).then(res => {
            let status = res[0];
            let data = res[1];

            if (status === 200) {
                setDas(data.das)
            }

            return {status, data}
        });
    }, [wi.uuid])

    // @ts-ignore
    const workflowHandler = WorkflowHandlers[wi.workflow_name];

    // TODO Sort
    const notesVal = <>
        {wi.history.map((h) => {
            if (!h.notes || h.notes === "") {
                return <></>
            }

            const toStepHandler = workflowHandler.steps[h.to_step];
            const fromStepHandler = workflowHandler.steps[h.from_step];

            if (h.to_step !== h.from_step) {
                return <div className={'history-note'}>
                    <div
                        className={'fw-700'}>{h.agent.name} {h.is_revert ? `reverted from ${fromStepHandler.displayName} to ${toStepHandler.displayName}` : `completed ${fromStepHandler.displayName}`}</div>
                    {h.notes}
                </div>
            }

            if (h.to_flagged !== h.from_flagged) {
                return <div className={'history-note'}>
                    <div
                        className={'fw-700'}>{h.agent.name} {h.to_flagged ? `flagged for review` : `removed flag`}</div>
                    {h.notes}
                </div>
            }

        })}
    </>

    const da = das[wi.data.service_id];

    let serviceMethodMessage = ""

    if (da) {
        if (wi.data.service_method === "mail"){
            serviceMethodMessage = "mail"
        } else if (wi.data.service_method === "email"){
            serviceMethodMessage = `email: ${da.email_address}`
        } else if (wi.data.service_method === "fax"){
            serviceMethodMessage = `fax: ${wi.data.service_use_backup_fax ? da.backup_fax_number : da.fax_number}`
        }
    }

    let data = {
        "Notes": notesVal,
        "First Name": wi.client.name.firstName,
        "Middle Name": wi.client.name.middleName,
        "Last Name": wi.client.name.lastName,
        "Email": wi.client.email,
        "DOB": wi.client.dateOfBirth,
        "Case #": wi.data.case_number,
        "Conv. Date": wi.data.conviction_date,
        "Code": wi.data.code,
        "Statute": wi.data.statute,
        "Charge Name": wi.data.charge_name,
        "Conv. Type": wi.data.conviction_type,
        "Prob. Violations": wi.data.probation_violations ? "true" : "false",
        "Remedy": wi.data.remedy,
        "Relief Type": wi.data.relief_type,
        "Court": wi.data.court,
        "CR-180 Misc.": (wi.data.cr180_checks || []).join(", "),
        "Serve To": da ? `${da.county} County - ${da.full_name}${da.branch ? ', ' : ''}${da.branch}`: '',
        "Service Method": serviceMethodMessage,
        // "Service Date": wi.data.service_date,
        // "Service By": wi.data.service_by_name,
        // "Service From": `${wi.data.service_by_city}, ${wi.data.service_by_state}`,
    }

    if (wi.data.remedy === "1203.43") {
        // @ts-ignore
        data["Dism. Date"] = wi.data.dismissal_date;
    }

    if (wi.data.drivers_number) {
        // @ts-ignore
        data["License #"] = wi.data.drivers_number;
    }

    return (
        <div className="workflow-data-view">
            {/*<h5>Workflow Data</h5>*/}
            <Table striped>
                <tbody>
                {Object.entries(data).map(([label, val]) => {
                    return <tr className={'data-item'}>
                        <td className="data-label">{label}</td>
                        <td className="data-value">{val}</td>
                    </tr>
                })}
                </tbody>
            </Table>

        </div>
    );
}

function StartStep({wi, refresh, refreshMetadata}: StepComponentProps) {
    // @ts-ignore
    const workflowHandler = WorkflowHandlers[wi.workflow_name];

    return <Container className={'step-container'}>
        <div className={'step-main-section'}>
            <h1>{wi.client.name.firstName} {wi.client.name.lastName}</h1>
            <h2>{workflowHandler.displayName}</h2>
            <h4>Created {(parseDate(wi.created_at)).toLocaleDateString("en", {timeZone: 'UTC'})}</h4>
            <br/>
            <Button variant={'success'} onClick={() => completeStart(wi, refreshMetadata)}>Start Workflow</Button>
        </div>
    </Container>
}

function validateClientInfoStep(wi: WorkflowInstance<any>): { valid: boolean, msg: string } {
    if (!wi.client.name.firstName) {
        return {valid: false, msg: "Client is missing first name"}
    }

    if (!wi.client.name.lastName) {
        return {valid: false, msg: "Client is missing last name"}
    }

    if (!wi.client.email) {
        return {valid: false, msg: "Client is missing email"}
    }

    if (!wi.client.dateOfBirth) {
        return {valid: false, msg: "Client is missing date of birth"}
    }

    if (dateFromMMDDYYYY(wi.client.dateOfBirth).toDateString() === new Date().toDateString()) {
        return {valid: false, msg: "Client date of birth is today"}
    }

    if (!wi.data.case_print_path) {
        return {valid: false, msg: "Missing uploaded case print"}
    }


    return {valid: true, msg: ""}
}

function ClientInfoStep({wi, refresh}: StepComponentProps) {
    const defaultDate = new Date();

    const [firstName, setFirstName] = React.useState(wi.client.name.firstName || "")
    const [middleName, setMiddleName] = React.useState(wi.client.name.middleName || "")
    const [lastName, setLastName] = React.useState(wi.client.name.lastName || "")
    const [email, setEmail] = React.useState(wi.client.email || "")
    const [selectedDate, setSelectedDate] = React.useState(wi.client.dateOfBirth ? dateFromMMDDYYYY(wi.client.dateOfBirth) : defaultDate);
    const [showUploadCasePrint, setShowUploadCasePrint] = React.useState(wi.data.case_print_path ? false : true);

    const [emailError, setEmailError] = React.useState("");

    const validateEmail = (email: string) => {
        // Simple email regex validation
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    };

    // TODO dedup
    function updateData(updates: any, logUpdates: any) {
        return fetch(API_URLS.updateWorkflowData, {
            method: 'POST',
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({
                instanceID: wi.uuid,
                updates: updates,
                logUpdates: logUpdates
            })
        }).then(res => Promise.all([res.status, res.json()])).then(res => {
            let status = res[0]
            let data = res[1]

            refresh()

            if (status === 200) {

            } else {
                alert("Could not update workflow data")  // TODO use global notifications for errors
            }

            return {status, data}
        });
    }

    const handleCasePrintFileChange = (e: any) => {
        const file: File = e.target.files[0];

        if (!file) {
            return
        }

        // Add file to form data
        const data = new FormData();
        data.append('file', file, file.name);
        data.append('instanceID', wi.uuid);
        data.append('fileName', "case-print.pdf");

        // Make request
        fetch(API_URLS.uploadWorkflowFile, {
            method: 'POST',
            body: data
        }).then(res => Promise.all([res.status, res.json()])).then(res => {
            let status = res[0]
            let data = res[1]

            if (status === 200) {
                // Save case print path
                updateData({"case_print_path": data.path}, {"case_print_path": ""}).then(() => refresh());
            }
        })
    };

    React.useEffect(() => {
        setFirstName(wi.client.name.firstName || "")
        setMiddleName(wi.client.name.middleName || "")
        setLastName(wi.client.name.lastName || "")
        setEmail(wi.client.email || "")
        setSelectedDate(wi.client.dateOfBirth ? dateFromMMDDYYYY(wi.client.dateOfBirth) : defaultDate);
        setShowUploadCasePrint(wi.data.case_print_path ? false : true)
    }, [wi])

    function updateClientInfoSingle(property: string, newVal: any) {
        fetch(API_URLS.updateClientInfo, {
            method: 'POST',
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({
                clientID: wi.client.uuid,
                property: property,
                value: newVal,
                workflowInstanceID: wi.uuid,
            })
        }).then(res => Promise.all([res.status, res.json()])).then(res => {
            let status = res[0]
            let data = res[1]

            refresh()

            if (status === 200) {

            } else {
                alert("Could not update client info")  // TODO use global notifications for errors
            }

            return {status, data}
        });
    }

    function updateClientInfo() {
        if (firstName !== wi.client.name.firstName) {
            updateClientInfoSingle("firstName", firstName)
        }
        if (middleName !== wi.client.name.middleName) {
            updateClientInfoSingle("middleName", middleName)
        }
        if (lastName !== wi.client.name.lastName) {
            updateClientInfoSingle("lastName", lastName)
        }
        if (email !== wi.client.email) {
            updateClientInfoSingle("email", email)
        }
        if (!wi.client.dateOfBirth && selectedDate === defaultDate) {
            // Do nothing
        } else { // @ts-ignore
            let selectedDateDateOnly = selectedDate;
            selectedDateDateOnly.setHours(0, 0, 0, 0);

            let originalDate = null;
            if (wi.client.dateOfBirth) {
                // @ts-ignore
                originalDate = dateFromMMDDYYYY(wi.client.dateOfBirth);
                originalDate.setHours(0, 0, 0, 0);
            }
            if ((!wi.client.dateOfBirth && selectedDate !== defaultDate) || (originalDate && selectedDateDateOnly.valueOf() !== originalDate.valueOf())) {
                updateClientInfoSingle("dateOfBirth", dateToMMDDYYYY(selectedDate))
            }
        }

    }

    // @ts-ignore
    const workflowHandler = WorkflowHandlers[wi.workflow_name];

    const currentStepHandler = workflowHandler.steps[wi.current_step]

    return <Container className={'step-container'} fluid>
        <div className={'step-main-section'}>
            <h2>Client Information</h2>
            <br/>
            <h5>Name</h5>
            <Form.Label>First Name</Form.Label>
            <Form.Control
                type={"text"}
                placeholder="First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                onBlur={() => updateClientInfo()}
            />
            <br/>
            <Form.Label>Middle Name</Form.Label>
            <Form.Control
                type={"text"}
                placeholder="Middle Name"
                value={middleName}
                onChange={(e) => setMiddleName(e.target.value)}
                onBlur={() => updateClientInfo()}
            />
            <br/>
            <Form.Label>Last Name</Form.Label>
            <Form.Control
                type={"text"}
                placeholder="Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                onBlur={() => updateClientInfo()}
            />
            <br/>
            <h5>Email</h5>
            <Form.Control
                type={"email"}
                placeholder="Email"
                value={email}
                onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailError("");
                }}
                onBlur={() => {
                    if (!validateEmail(email)) {
                        setEmailError('Please enter a valid email');
                    } else {
                        setEmailError('');
                        updateClientInfo();
                    }
                }}
            />
            {emailError && <p style={{color: 'red'}}>{emailError}</p>}
            <br/>
            <h5>Date of Birth</h5>
            <DatePicker selected={selectedDate} onChange={(newVal: Date) => {
                setSelectedDate(newVal)
            }} onBlur={() => updateClientInfo()}/>
            <br/>
            <br/>
            <h5>Case Print</h5>
            {wi.data.case_print_path && <Badge bg={'success'} className={'me-2'}>Case Print Uploaded</Badge>}
            {showUploadCasePrint
                ? <Form.Control type="file" onChange={handleCasePrintFileChange}/>
                : <Button variant={'outline-primary'} onClick={() => setShowUploadCasePrint(true)}>Upload New Case
                    Print</Button>
            }

        </div>
        <div className={'workflow-checkist-bar'}>
            <CA12034DataView wi={wi}/>
            <StepChecklist wi={wi} tasks={currentStepHandler.checkTasks} refresh={refresh}/>
        </div>
    </Container>
}

function CaseComment({c}: { c: ExtractedComment }) {
    return <div className={'case-comment'}>
        <div className={'case-comment-date'}>{formatDate(c.date)}</div>
        {c.text}
    </div>
}

function CaseCourtDate({c}: { c: CourtDate }) {
    return <div className={'case-comment'}>
        <div className={'case-comment-date'}>{formatDate(c.date)}</div>
        {c.court}
    </div>
}

function CaseCardCount({
                           c,
                           mini,
                           showSelect,
                           onSelect
                       }: { c: ExtractedCount, mini?: boolean, showSelect: boolean, onSelect?: any }) {
    const flags = {
        attempted: c.attempted,
        charge_in_comment: c.charge_in_comment,
        marked_as_probation_violation: c.marked_as_probation_violation,
        marked_as_parole_violation: c.marked_as_parole_violation,
        ...c.misc_flags
    }

    return <div className={`count ${c.sentences.length > 0 ? "with-sentence" : ""}`}>
        <div className={'count-title'}>
            {c.count_number} {c.type_of_crime}{!mini ? <br/> : " "}{c.code} {c.statute} {c.charge_name}
            {showSelect && <Button className={'count-select-btn'} size={'sm'} variant={'success'}
                                   onClick={() => onSelect()}>Select</Button>}
        </div>
        {c.secondary_statute && <div className={'count-title'}>
            {c.secondary_code} {c.secondary_statute} {c.secondary_charge_name}
        </div>
        }
        {c.convicted && <Badge
            bg={'danger'}>Convicted {formatDateObj(dateFromMMDDYYYY(c.convicted_on || ""))} {c.conviction_type}</Badge>}
        {c.dismissed && <Badge bg={'success'}>Dismissed {formatDateObj(dateFromMMDDYYYY(c.dismissed_on || ""))}</Badge>}
        {c.case_number && <div className={'count-id'}>
            <div className={'count-id-label'}>Case Number</div>
            {c.case_number}
        </div>}
        {c.record_id && <div className={'count-id'}>
            <div className={'count-id-label'}>Record ID</div>
            {c.record_id}
        </div>}
        {c.warrant_number && <div className={'count-id'}>
            <div className={'count-id-label'}>Warrant Number</div>
            {c.warrant_number}
        </div>}
        {c.state_control_number && <div className={'count-id'}>
            <div className={'count-id-label'}>State Control Number</div>
            {c.state_control_number}
        </div>}
        {c.document_control_number && <div className={'count-id'}>
            <div className={'count-id-label'}>Document Control Number</div>
            {c.document_control_number}
        </div>}
        {<div className={'count-flags'}>
            {Object.entries(flags).map(([l, v]) => v && <Badge bg={'primary'}>{l}</Badge>)}
        </div>}
        {!mini && c.sentences.length > 0 && (<div className={'count-comments count-sentences'}>
            <div className={'comments-title'}>Sentences</div>
            {c.sentences.map((cc) => <CaseComment c={cc}/>)}
        </div>)}{!mini && c.sentences.length > 0 && (<div className={'count-comments count-sentences'}>
        <div className={'comments-title'}>Sentences</div>
        {c.sentences.map((cc) => <CaseComment c={cc}/>)}
    </div>)}
        {!mini && c.comments.length > 0 && (<div className={'count-comments'}>
            <div className={'comments-title'}>Comments</div>
            {c.comments.map((cc) => <CaseComment c={cc}/>)}
        </div>)}
        {!mini && c.dispositions.length > 0 && (<div className={'count-comments'}>
            <div className={'comments-title'}>Dispositions</div>
            {c.dispositions.map((cc) => <CaseComment c={cc}/>)}
        </div>)}
    </div>
}

export function CaseCard({
                             c,
                             showSelect,
                             onSelect,
                             onlyCount
                         }: { c: ExtractedCase, showSelect: boolean, onSelect?: any, onlyCount?: string }) {
    /* onSelect received args selected case, selected count */
    const [showFullView, setShowFullView] = React.useState(false);

    const options = {
        year: "2-digit",
        month: "numeric",
        day: "numeric",
        // timeZone: 'UTC',
    };
    // @ts-ignore
    const arrestTimeString = (dateFromMMDDYYYY(c.arrest_date)).toLocaleDateString("en", options);

    return <>
        <Card className={'case-card'}>
            <Card.Body>
                {/*<Card.Title>{workflow.displayName}</Card.Title>*/}
                <Card.Text>
                    <div>
                        <div className={'arrest-info'}>Arrest on {arrestTimeString} by {c.arrest_agency}</div>
                        {onlyCount
                            ? <CaseCardCount c={c.counts[onlyCount]} showSelect={showSelect}
                                             onSelect={() => onSelect(c, c.counts[onlyCount])}/>
                            : Object.values(c.counts).map((cc) => <CaseCardCount mini={true} c={cc}
                                                                                 showSelect={showSelect}
                                                                                 onSelect={() => onSelect(c, cc)}/>)
                        }
                        {(c.court_dates).length > 0 && <br/>}
                        {c.court_dates.length > 0 && (<div className={'count-comments count-court-dates'}>
                            <div className={'comments-title'}>Court Dates</div>
                            {c.court_dates.map((cc) => <CaseCourtDate c={cc}/>)}
                        </div>)}
                        {(c.case_level_sentences).length > 0 && <br/>}
                        {c.case_level_sentences.length > 0 && (<div className={'count-comments count-sentences'}>
                            <div className={'comments-title'}>Case Sentences</div>
                            {c.case_level_sentences.map((cc) => <CaseComment c={cc}/>)}
                        </div>)}
                    </div>
                </Card.Text>
                <Button variant={'outline-primary'} onClick={() => setShowFullView(true)}>Show Details</Button>
            </Card.Body>
        </Card>
        <Modal className={'case-view-modal'} show={showFullView} onHide={() => {
            setShowFullView(false)
        }}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <div className={'arrest-info'}>Arrest on {arrestTimeString} by {c.arrest_agency}</div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {onlyCount
                    ? <CaseCardCount c={c.counts[onlyCount]} showSelect={showSelect}
                                     onSelect={() => onSelect(c, c.counts[onlyCount])}/>
                    : Object.values(c.counts).map((cc) => <CaseCardCount c={cc} showSelect={showSelect}
                                                                         onSelect={() => onSelect(c, cc)}/>)
                }
                {/* @ts-ignore */}
                {(c.case_level_sentences.concat(c.case_level_comments).concat(c.case_level_dispositions).concat(c.court_dates)).length > 0 &&
                    <br/>}
                {c.court_dates.length > 0 && (<div className={'count-comments count-court-dates'}>
                    <div className={'comments-title'}>Court Dates</div>
                    {c.court_dates.map((cc) => <CaseCourtDate c={cc}/>)}
                </div>)}
                {c.case_level_sentences.length > 0 && (<div className={'count-comments count-sentences'}>
                    <div className={'comments-title'}>Case Sentences</div>
                    {c.case_level_sentences.map((cc) => <CaseComment c={cc}/>)}
                </div>)}
                {c.case_level_comments.length > 0 && (<div className={'count-comments'}>
                    <div className={'comments-title'}>Case Comments</div>
                    {c.case_level_comments.map((cc) => <CaseComment c={cc}/>)}
                </div>)}
                {c.case_level_dispositions.length > 0 && (<div className={'count-comments'}>
                    <div className={'comments-title'}>Case Dispositions</div>
                    {c.case_level_dispositions.map((cc) => <CaseComment c={cc}/>)}
                </div>)}
            </Modal.Body>
        </Modal>
    </>


}

export function weightedJaccardSimilarity(arrA: any, arrB: any) {
    const mapA = createFrequencyMap(arrA);
    const mapB = createFrequencyMap(arrB);

    let intersection = 0;
    let union = 0;

    for (const [key, value] of mapA.entries()) {
        if (mapB.has(key)) {
            intersection += Math.min(value, mapB.get(key));
        }
        union += value;
    }

    for (const [key, value] of mapB.entries()) {
        if (!mapA.has(key)) {
            union += value;
        }
    }

    return intersection / union;
}

function createFrequencyMap(arr: any) {
    const map = new Map();
    for (const item of arr) {
        map.set(item, (map.get(item) || 0) + 1);
    }
    return map;
}

function stringSimilarity(sa: any, sb: any) {
    // Modified jaccard similarity
    return weightedJaccardSimilarity(sa.toLowerCase(), sb.toLowerCase())
    // let setA = new Set([...sa.toLowerCase()]);
    // let setB = new Set([...sb.toLowerCase()]);
    // const intersection = new Set([...setA].filter(x => setB.has(x)));
    // const union = new Set([...setA, ...setB]);
    // return intersection.size / union.size;
}

function fuzzySearchCharge(caseNumber: string, cases: Array<ExtractedCase>): { caseUUID?: string, countNumber?: string } {
    let bestCaseUUID: string | undefined = undefined;
    let bestCountNumber: string | undefined = undefined;
    let bestScore = -1;

    for (const cse of cases) {
        for (const count of Object.values(cse.counts)) {
            const scoreCase = -1 * stringSimilarity(caseNumber, count.case_number || "");
            const scoreWarrant = -1 * stringSimilarity(caseNumber, count.warrant_number || "");
            const scoreRecord = -1 * stringSimilarity(caseNumber, count.record_id || "");
            const scoreState = -1 * stringSimilarity(caseNumber, count.state_control_number || "");
            const scoreDoc = -1 * stringSimilarity(caseNumber, count.document_control_number || "");
            const score = -1 * [scoreCase, scoreWarrant, scoreRecord, scoreState, scoreDoc].sort()[0]
            if (score >= bestScore) {
                bestCaseUUID = cse.uuid;
                bestCountNumber = count.count_number || undefined;
                bestScore = score;
            }
        }
    }

    return {caseUUID: bestCaseUUID, countNumber: bestCountNumber};
}

function validateChargeInfoStep(wi: WorkflowInstance<any>): { valid: boolean, msg: string } {
    if (!wi.data.case_number) {
        return {valid: false, msg: "Case number is missing"}
    }
    if (!wi.data.conviction_date) {
        return {valid: false, msg: "Conviction date is missing"}
    }
    if (!wi.data.statute) {
        return {valid: false, msg: "Statute is missing"}
    }
    if (!wi.data.code) {
        return {valid: false, msg: "Code is missing"}
    }
    if (!wi.data.charge_name) {
        return {valid: false, msg: "Charge name is missing"}
    }
    if (!wi.data.conviction_type) {
        return {valid: false, msg: "Conviction type is missing"}
    }

    return {valid: true, msg: ""}
}

function ChargeInfoStep({wi, refresh}: StepComponentProps) {
    const [rapData, setRapData] = React.useState<RapSheetExtractedData | null>(null)
    const [showSelectCharge, setShowSelectCharge] = React.useState<boolean>(false)
    const [caseNumber, setCaseNumber] = React.useState((wi as WorkflowInstance<CA12034Data>).data.case_number || "")
    const [selectedDate, setSelectedDate] = React.useState((wi as WorkflowInstance<CA12034Data>).data.conviction_date ? dateFromMMDDYYYY((wi as WorkflowInstance<CA12034Data>).data.conviction_date as string) : new Date());
    const [statute, setStatute] = React.useState((wi as WorkflowInstance<CA12034Data>).data.statute || "")
    const [code, setCode] = React.useState((wi as WorkflowInstance<CA12034Data>).data.code || "")
    const [chargeName, setChargeName] = React.useState((wi as WorkflowInstance<CA12034Data>).data.charge_name || "")
    const [convictionType, setConvictionType] = React.useState((wi as WorkflowInstance<CA12034Data>).data.conviction_type || "")

    React.useEffect(() => {
        // Update state from new instance
        setCaseNumber((wi as WorkflowInstance<CA12034Data>).data.case_number || "")
        setSelectedDate((wi as WorkflowInstance<CA12034Data>).data.conviction_date ? dateFromMMDDYYYY((wi as WorkflowInstance<CA12034Data>).data.conviction_date as string) : new Date())
        setStatute((wi as WorkflowInstance<CA12034Data>).data.statute || "")
        setCode((wi as WorkflowInstance<CA12034Data>).data.code || "")
        setChargeName((wi as WorkflowInstance<CA12034Data>).data.charge_name || "")
        setConvictionType((wi as WorkflowInstance<CA12034Data>).data.conviction_type || "")
    }, [wi])

    React.useEffect(() => {
        // Get rap sheet data
        fetch(API_URLS.getClientRapSheetData, {
            method: 'POST',
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({clientID: wi.client.uuid})
        }).then(res => Promise.all([res.status, res.json()])).then(res => {
            let status = res[0];
            let data = res[1];

            if (status === 200) {
                setRapData(data.extracted)
            }

            return {status, data}
        });
    }, [wi.uuid])

    function updateData(updates: any, logUpdates: any) {
        return fetch(API_URLS.updateWorkflowData, {
            method: 'POST',
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({
                instanceID: wi.uuid,
                updates: updates,
                logUpdates: logUpdates
            })
        }).then(res => Promise.all([res.status, res.json()])).then(res => {
            let status = res[0]
            let data = res[1]

            refresh()

            if (status === 200) {

            } else {
                alert("Could not update workflow data")  // TODO use global notifications for errors
            }

            return {status, data}
        });
    }

    function onCountSelect(cse?: ExtractedCase | null, count?: ExtractedCount | null) {
        updateData({
                "case_uuid": cse ? cse.uuid : null,
                "count_number": count ? count.count_number : null,
                "conviction_date": count && count.convicted_on ? count.convicted_on : null,
                "statute": count ? count.statute : null,
                "code": count ? count.code : null,
                "charge_name": count ? count.charge_name : null,
                "conviction_type": count ? count.conviction_type : null,
                "hearing_court": cse && cse.court_dates.length > 0 ? cse.court_dates.at(-1)?.court : null,
            },
            {
                "case_uuid": cse ? `Arrest ${formatDate(cse.arrest_date)} by ${cse.arrest_agency}` : "None",
                "count_number": count ? `${count.code} ${count.statute} ${count.charge_name}` : "None"
            }
        ).then(() => {
            setShowSelectCharge(false);
        })
    }


    const cases = rapData?.cases


    // @ts-ignore
    const workflowHandler = WorkflowHandlers[wi.workflow_name];

    const searchMatch = fuzzySearchCharge(caseNumber, cases || []);
    const searchCaseUUID = searchMatch.caseUUID;
    const searchCountNumber = searchMatch.countNumber;
    const searchCase = cases?.filter(c => c.uuid === searchCaseUUID)[0];
    const searchCount = searchCountNumber && searchCase?.counts[searchCountNumber as string];


    const currentStepHandler = workflowHandler.steps[wi.current_step]
    const selectedCaseUUID = (wi as WorkflowInstance<CA12034Data>).data.case_uuid;
    const selectedCase = cases?.filter(c => c.uuid === selectedCaseUUID)[0];
    const selectedCountNumber = (wi as WorkflowInstance<CA12034Data>).data.count_number;
    const selectedCount = selectedCountNumber && selectedCase?.counts[selectedCountNumber as string];
    return <Container className={'step-container'} fluid>
        <div className={'step-main-section'}>
            <h2>Charge Information</h2>
            <br/>

            <h5>Case Number (make sure to enter entire case number)</h5>
            <Form.Control
                type={"text"}
                placeholder="Case Number"
                value={caseNumber}
                onChange={(e) => setCaseNumber(e.target.value)}
                onBlur={() => updateData({"case_number": caseNumber}, {})}
            />
            <br/>

            <div className={'case-select-section'}>
                {caseNumber.length >= 3 && searchCount && !selectedCount && <>
                    <div className={'d-flex'}>
                        <h5>Best Match</h5>
                    </div>
                    <div className={'cases'}>
                        <CaseCard c={searchCase} showSelect={true} onSelect={onCountSelect}/>
                    </div>
                    <br/>
                </>}
                {(!selectedCount || showSelectCharge) && <>
                    <div className={'d-flex'}>
                        <h5>Select a Charge</h5>
                        {showSelectCharge && <Button className={'change-charge-btn'} variant={'outline-primary'}
                                                     onClick={() => setShowSelectCharge(false)}>Close</Button>}
                    </div>
                    {!rapData && "No Rap Sheet found"}
                    <div className={'cases'}>
                        {/* @ts-ignore */}
                        {cases?.sort((a, b) => new Date(a.arrest_date) - new Date(b.arrest_date)).map(c => <CaseCard
                            c={c} showSelect={true} onSelect={onCountSelect}/>)}
                    </div>
                </>
                }
                {selectedCount && <>
                    <div className={'d-flex'}>
                        <h5>Selected Charge</h5>
                        <Button className={'change-charge-btn'} variant={'outline-primary'}
                                onClick={() => setShowSelectCharge(true)}>Change</Button>
                        <Button className={'remove-charge-btn'} variant={'outline-danger'}
                                onClick={() => onCountSelect(null, null)}>Remove</Button>
                    </div>
                    <div className={'cases'}>
                        <CaseCard c={selectedCase} showSelect={false} onlyCount={selectedCountNumber}/>
                    </div>
                </>}

                <br/>
                <h5>Conviction Date</h5>
                <DatePicker selected={selectedDate} onChange={(newVal: Date) => {
                    setSelectedDate(newVal);
                    updateData({"conviction_date": dateToMMDDYYYY(newVal)}, {})
                }}/>
                <br/>
                <br/>

                <h5>Code</h5>
                <Form.Control
                    type={"text"}
                    placeholder="Code"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    onBlur={() => updateData({"code": code}, {})}
                />
                <br/>

                <h5>Statute</h5>
                <Form.Control
                    type={"text"}
                    placeholder="Statute"
                    value={statute}
                    onChange={(e) => setStatute(e.target.value)}
                    onBlur={() => updateData({"statute": statute}, {})}
                />
                <br/>

                <h5>Charge Name</h5>
                <Form.Control
                    type={"text"}
                    placeholder="Charge Name"
                    value={chargeName}
                    onChange={(e) => setChargeName(e.target.value)}
                    onBlur={() => updateData({"charge_name": chargeName}, {})}
                />
                <br/>

                <h5>Conviction Type</h5>
                <Form.Select value={convictionType} onChange={(e) => {
                    setConvictionType(e.target.value);
                    updateData({"conviction_type": e.target.value}, {})
                }}>
                    {["", "MISDEMEANOR", "FELONY", "INFRACTION"].map((option) => <option
                        value={option}>{option || "-"}</option>)}
                </Form.Select>
                <br/>


            </div>
        </div>
        <div className={'workflow-checkist-bar'}>
            <CA12034DataView wi={wi}/>
            <StepChecklist wi={wi} tasks={currentStepHandler.checkTasks} refresh={refresh}/>
        </div>
    </Container>
}

function validateEligibilityStep(wi: WorkflowInstance<any>): { valid: boolean, msg: string } {
    if (!wi.data.relief_type) {
        return {valid: false, msg: "Relief type is missing"}
    }
    if (!wi.data.remedy) {
        return {valid: false, msg: "Remedy type is missing"}
    }

    return {valid: true, msg: ""}
}

enum ReliefTypes {
    MANDATORY = "MANDATORY",
    DISCRETIONARY = "DISCRETIONARY",
}

const GLOBAL_REQUIREMENTS = [
    "Not currently serving a sentence",
    "Not currently facing charges"
]
const REMEDIES_INFO = {
    "": {
        value: "",
        description: "",
        types: {},
        requirements: []
    },
    "1203.4": {
        value: "1203.4",
        description: "Felony/misdemeanor with probation",
        types: {
            [ReliefTypes.MANDATORY]: "",
            [ReliefTypes.DISCRETIONARY]: "If probation violations, or DUI"
        },
        requirements: [
            ...GLOBAL_REQUIREMENTS,
            "Felony or misdemeanor",
            "Probation granted and completed",
            "All fines, restitution, and reimbursement paid",
        ]
    },
    "1203.4a": {
        value: "1203.4a",
        description: "Infraction/misdemeanor without probation",
        types: {
            [ReliefTypes.MANDATORY]: "",
            [ReliefTypes.DISCRETIONARY]: "If currently facing charges/serving sentence"
        },
        requirements: [
            ...GLOBAL_REQUIREMENTS,
            "Infraction or misdemeanor",
            "Probation not granted",
            "Completed sentence",
            "1 year elapsed since conviction date"
        ]
    },
    "1203.41": {
        value: "1203.41",
        description: "Felony sentence served in jail (1170(h)(5))",
        types: {
            [ReliefTypes.DISCRETIONARY]: ""
        },
        requirements: [
            ...GLOBAL_REQUIREMENTS,
            "Completed felony sentence in county jail",
            "Sentence and supervision completed",
            "1 year elapsed since completion of sentence",
        ]
    },
    "1203.42": {
        value: "1203.42",
        description: "Pre-2011 eligible for 1170(h)(5)",
        types: {
            [ReliefTypes.DISCRETIONARY]: ""
        },
        requirements: [
            ...GLOBAL_REQUIREMENTS,
            "Would be eligible under 1170(h)(5) if charge was 2011 or after",
            "Sentence and supervision completed",
            "2 years elapsed since completion of sentence"
        ]
    },
    "1203.43": {
        value: "1203.43",
        description: "Deferred Entry of Judgement",
        types: {
            [ReliefTypes.MANDATORY]: "",
        },
        requirements: [
            ...GLOBAL_REQUIREMENTS,
            "Granted deferred entry of judgment",
            "Successfully completed DEJ program",
            "All terms and conditions of the DEJ program fulfilled",
        ]
    },
    "1203.49": {
        value: "1203.49",
        description: "Human Trafficking Victims",
        types: {
            [ReliefTypes.DISCRETIONARY]: ""
        },
        requirements: [
            ...GLOBAL_REQUIREMENTS,
            "Victim of human trafficking",
            "Non-violent crime as result of trafficking",
            "Proof required",
        ]
    },
}

function EligibilityStep({wi, refresh}: StepComponentProps) {
    const [rapData, setRapData] = React.useState<RapSheetExtractedData | null>(null)
    const [showHistory, setShowHistory] = React.useState<boolean>(true)
    const [reliefType, setReliefType] = React.useState<string>((wi as WorkflowInstance<CA12034Data>).data.relief_type || "")
    const [remedy, setRemedy] = React.useState<string>((wi as WorkflowInstance<CA12034Data>).data.remedy || "")

    const convictionDateRaw = (wi as WorkflowInstance<CA12034Data>).data.conviction_date;
    const convictionDate = convictionDateRaw ? dateFromMMDDYYYY(convictionDateRaw) : "";

    // Relevant History
    const allCases = rapData?.cases
    const arrestsSinceConviction = allCases?.filter((cse) => dateFromMMDDYYYY(cse.arrest_date) >= convictionDate)

    const cases = arrestsSinceConviction;

    React.useEffect(() => {
        // Update state from new instance
        setReliefType((wi as WorkflowInstance<CA12034Data>).data.relief_type || "")
        setRemedy((wi as WorkflowInstance<CA12034Data>).data.remedy || "")
    }, [wi])

    React.useEffect(() => {
        // Get rap sheet data
        fetch(API_URLS.getClientRapSheetData, {
            method: 'POST',
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({clientID: wi.client.uuid})
        }).then(res => Promise.all([res.status, res.json()])).then(res => {
            let status = res[0];
            let data = res[1];

            if (status === 200) {
                setRapData(data.extracted)
            }

            return {status, data}
        });
    }, [wi.uuid])

    // TODO Dedup
    function updateData(updates: any, logUpdates: any) {
        return fetch(API_URLS.updateWorkflowData, {
            method: 'POST',
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({
                instanceID: wi.uuid,
                updates: updates,
                logUpdates: logUpdates
            })
        }).then(res => Promise.all([res.status, res.json()])).then(res => {
            let status = res[0]
            let data = res[1]

            refresh()

            if (status === 200) {

            } else {
                alert("Could not update workflow data")  // TODO use global notifications for errors
            }

            return {status, data}
        });
    }

    // @ts-ignore
    const workflowHandler = WorkflowHandlers[wi.workflow_name];
    const currentStepHandler = workflowHandler.steps[wi.current_step]

    // Relief logic
    // @ts-ignore
    const chosenRemedyInfo = REMEDIES_INFO[remedy];
    const allowedTypes: { [k: string]: string } = chosenRemedyInfo ? chosenRemedyInfo.types : {};
    const allowedTypesWithEmpty = {"": "", ...allowedTypes};
    const chosenRemedyRequirements = chosenRemedyInfo ? chosenRemedyInfo.requirements : [];


    // @ts-ignore
    return <Container className={'step-container'} fluid>
        <div className={'step-main-section'}>
            <h2>Verify Eligibility</h2>
            <br/>
            <h5>Probation Violations?</h5>
            <Form.Check
                type="switch"
                id="probation-violations-switch"
                label="Probation Violations"
                checked={wi.data.probation_violations || false}
                onChange={(e) => {
                    updateData({"probation_violations": e.target.checked}, {})
                }}
            />
            <br/>
            <h5>Remedy</h5>
            <Form.Select value={remedy} onChange={(e) => {
                setRemedy(e.target.value);
                updateData({"remedy": e.target.value, "relief_type": ""}, {})
            }}>
                {Object.values(REMEDIES_INFO).map(({value, description}) => <option
                    value={value}>{value ? `${value} - ${description}` : "-"}</option>)}
            </Form.Select>

            <br/><h5>Relief Type</h5>
            <Form.Select value={reliefType} onChange={(e) => {
                setReliefType(e.target.value);
                updateData({"relief_type": e.target.value}, {})
            }}>
                {Object.entries(allowedTypesWithEmpty).map(([option, description]) => <option
                    value={option}>{description ? `${option} - ${description}` : (option || "-")}</option>)}
            </Form.Select>

            <br/><h5>Requirements</h5>
            {chosenRemedyRequirements.map((t: string) => {
                return <div className={'eligibility-requirement'}>
                    - {t}
                </div>
            })}
            <br/>


            {showHistory && <div className={'case-select-section'}>
                <div className={'d-flex'}>
                    <h5>Arrests Since Selected Conviction Date ({formatDateObj(convictionDate)})</h5>
                </div>
                {!rapData && "No Rap Sheet found"}
                <div className={'cases'}>
                    {/* @ts-ignore */}
                    {cases?.sort((a, b) => new Date(a.arrest_date) - new Date(b.arrest_date)).map(c => <CaseCard c={c}
                                                                                                                 showSelect={false}/>)}
                    {cases && cases.length === 0 && "No arrests found since conviction date"}
                </div>
            </div>
            }

        </div>
        <div className={'workflow-checkist-bar'}>
            <CA12034DataView wi={wi}/>
            <StepChecklist wi={wi} tasks={currentStepHandler.checkTasks} refresh={refresh}/>
        </div>
    </Container>
}

function validateCourtInfoStep(wi: WorkflowInstance<any>): { valid: boolean, msg: string } {
    if (!wi.data.court) {
        return {valid: false, msg: "Court missing"}
    }

    return {valid: true, msg: ""}
}

export type CourtLogicRule = {
    name: string | null,
    county: string | null,
    logic: string | null,
    value: string | null,
    to_name: string | null,
    to_county: string | null,
}
export type CourtContact = {
    name: string | null,
    county: string | null,
    mailing_address: string | null,
    street_address: string | null,
    city: string | null,
    state: string | null,
    zipcode: string | null,
    phone_number: string | null,
    fax_number: string | null,
    email_address: string | null,
}

function CourtContactCard({c, onSelect}: { c: CourtContact, onSelect?: any }) {
    return <Card className={'court-contact-card'}>
        <Card.Body>
            <Card.Title>
                {c.name} - {c.county} County
                {onSelect && <Button size={'sm'} variant={'success'} onClick={() => onSelect(c)}>Select</Button>}
            </Card.Title>
            <Card.Text>
                {c.mailing_address}
                <br/>
                {c.city} {c.state} {c.zipcode}
                {/*<br/>*/}
                {/*{c.phone_number && <><br/>Phone: {c.phone_number}</>}*/}
                {/*{c.fax_number && <><br/>Fax: {c.fax_number}</>}*/}
                {/*{c.email_address && <><br/>Email: {c.email_address}</>}*/}
            </Card.Text>
        </Card.Body>
    </Card>
}

function courtRuleMatches(courtRule: CourtLogicRule, court: CourtContact, arrestAgency: string): string | null {
    if (courtRule.county === court.county && courtRule.name === court.name) {
        if (courtRule.logic === "forward") {
            return courtRule.to_name
        } else if (courtRule.logic === "arrest_agency" && arrestAgency === courtRule.value) {
            return courtRule.to_name
        }
    }

    return null
}

function checkCourtRules(courtRules: Array<CourtLogicRule>, court: CourtContact, arrestAgency: string): { matchedCourt: string | null, matchedRule: CourtLogicRule | null } {
    let matchedCourt = null;
    let matchedRule = null;

    for (const courtRule of courtRules) {
        const match = courtRuleMatches(courtRule, court, arrestAgency);

        if (match !== null) {
            // Don't override if matched a non-forward rule
            if (matchedRule !== null && matchedRule.logic !== "forward") {
                // Do nothing
            }
            // Otherwise save
            else {
                matchedCourt = match
                matchedRule = courtRule
            }

        }

    }

    return {
        matchedCourt,
        matchedRule
    }
}

function courtRuleDescription(rule: CourtLogicRule): string {
    if (rule.logic === "arrest_agency") {
        return `Forward ${rule.name} to ${rule.to_name} if arrest agency is ${rule.value}`
    }

    return `Forward ${rule.name} to ${rule.to_name}`
}

function CourtInfoStep({wi, refresh}: StepComponentProps) {
    const [courts, setCourts] = React.useState<{ [k: string]: CourtContact }>({})
    const [courtLogicRules, setCourtLogicRules] = React.useState<Array<CourtLogicRule>>([])
    const [courtQuery, setCourtQuery] = React.useState<string>("")
    const [rapData, setRapData] = React.useState<RapSheetExtractedData | null>(null)

    function fuzzySearchCourts(query: string, n: number): CourtContact[] {
        let courtScores: { court: CourtContact, score: number }[] = [];

        for (const court of Object.values(courts)) {
            const score = stringSimilarity(query, court.name);
            courtScores.push({court, score});
        }

        courtScores.sort((a, b) => b.score - a.score);

        return courtScores.slice(0, n).map(cs => cs.court);
    }

    React.useEffect(() => {
        fetch(API_URLS.getCourtDirectory, {
            method: 'GET',
        }).then(res => Promise.all([res.status, res.json()])).then(res => {
            let status = res[0];
            let data = res[1];

            if (status === 200) {
                setCourts(data.courts)
                setCourtLogicRules(data.rules)
            }

            return {status, data}
        });

        // Get rap sheet data
        fetch(API_URLS.getClientRapSheetData, {
            method: 'POST',
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({clientID: wi.client.uuid})
        }).then(res => Promise.all([res.status, res.json()])).then(res => {
            let status = res[0];
            let data = res[1];

            if (status === 200) {
                setRapData(data.extracted)
            }

            return {status, data}
        });
    }, [wi.uuid])

    // TODO dedup
    function updateData(updates: any, logUpdates: any) {
        return fetch(API_URLS.updateWorkflowData, {
            method: 'POST',
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({
                instanceID: wi.uuid,
                updates: updates,
                logUpdates: logUpdates
            })
        }).then(res => Promise.all([res.status, res.json()])).then(res => {
            let status = res[0]
            let data = res[1]

            refresh()

            if (status === 200) {

            } else {
                alert("Could not update workflow data")  // TODO use global notifications for errors
            }

            return {status, data}
        });
    }

    function handleSelectCourtContact(c: CourtContact) {
        updateData({"court": c.name}, {})
        setCourtQuery("")
    }

    // @ts-ignore
    const workflowHandler = WorkflowHandlers[wi.workflow_name];

    const currentStepHandler = workflowHandler.steps[wi.current_step]

    const selectedCourt = courts[wi.data.court];

    // Get arresting agency
    const selectedCase = rapData && rapData.cases.filter(c => c.uuid === wi.data.case_uuid)[0];
    const selectedCaseArrestAgency = selectedCase?.arrest_agency;

    const ruleMatch = selectedCaseArrestAgency && selectedCourt && checkCourtRules(courtLogicRules, selectedCourt, selectedCaseArrestAgency);
    const {matchedCourt, matchedRule} = ruleMatch || {matchedCourt: null, matchedRule: null}

    return <Container className={'step-container'} fluid>
        <div className={'step-main-section'}>
            <h2>Court Information</h2>
            {/*<br/>*/}
            {/*<strong>Most Recent Court from Selected Case:</strong> {wi.data.hearing_court || "None"}*/}
            {/*{selectedCaseArrestAgency && <><br/><strong>Arresting Agency:</strong> {selectedCaseArrestAgency}</>}*/}
            {/*<br/>*/}
            <br/>

            <h5>Search for Court</h5>
            <Form.Control
                type={"text"}
                placeholder="Court Name"
                value={courtQuery}
                onChange={(e) => setCourtQuery(e.target.value)}
            />
            <br/>

            <div className={'court-contacts'}>
                {courtQuery && fuzzySearchCourts(courtQuery, 3).map((c) => <CourtContactCard c={c}
                                                                                             onSelect={handleSelectCourtContact}/>)}
            </div>
            <br/>

            {selectedCourt && <>
                <h5>Selected Court</h5>
                <CourtContactCard c={selectedCourt}/>
            </>}

            {matchedCourt && matchedRule && <>
                <br/>
                <h5>Suggested Replacement</h5>
                {courtRuleDescription(matchedRule)}
                <br/>
                <br/>
                <CourtContactCard c={courts[matchedCourt]} onSelect={handleSelectCourtContact}/>
            </>}

        </div>
        <div className={'workflow-checkist-bar'}>
            <CA12034DataView wi={wi}/>
            <StepChecklist wi={wi} tasks={currentStepHandler.checkTasks} refresh={refresh}/>
        </div>
    </Container>
}

const checkOptions = { // Should mirror back end string values
    pc17b: "PC 17(b)",
    pc17d2: "PC 17(d)(2)",
    twoa: "2a",
    twob: "2b",
    fivea: "5a",
    fiveb: "5b",
    fivec: "5c",
    sevena: "7a",
    sevenb1: "7b1",
    sevenb2: "7b2",
}

function validatePetitionStep(wi: WorkflowInstance<any>): { valid: boolean, msg: string } {
    const checks = wi.data.cr180_checks || [];
    const has41check = checks.includes(checkOptions.fivea) || checks.includes(checkOptions.fiveb) || checks.includes(checkOptions.fivec);
    const has43check = checks.includes(checkOptions.sevena) || checks.includes(checkOptions.sevenb1) || checks.includes(checkOptions.sevenb2);

    if (wi.data.remedy === "1203.41" && !has41check) {
        return {valid: false, msg: "Sentence completion missing"}
    }
    if (wi.data.remedy === "1203.43") {
        if (!has43check) {
            return {valid: false, msg: "Case resolution missing"}
        }

        if (!wi.data.dismissal_date) {
            return {valid: false, msg: "Dismissal date missing"}
        }

        if (dateFromMMDDYYYY(wi.data.dismissal_date).toDateString() === new Date().toDateString()) {
            return {valid: false, msg: "Dismissal date is today"}
        }
    }

    return {valid: true, msg: ""}
}

function PetitionStep({wi, refresh}: StepComponentProps) {
    const defaultDate = new Date();

    const [courts, setCourts] = React.useState<{ [k: string]: CourtContact }>({})
    // const [rapData, setRapData] = React.useState<RapSheetExtractedData | null>(null)
    const [dismissalDate, setDismissalDate] = React.useState(wi.data.dismissal_date ? dateFromMMDDYYYY(wi.data.dismissal_date) : defaultDate);
    const [showPetitionPreviewOverlay, setShowPetitionPreviewOverlay] = React.useState<boolean>(false);
    const [petitionFilePath, setPetitionFilePath] = React.useState<string | null>(wi.data.petition_path);
    const [driversNumber, setDriversNumber] = React.useState((wi as WorkflowInstance<CA12034Data>).data.drivers_number || "")

    React.useEffect(() => {
        fetch(API_URLS.getCourtDirectory, {
            method: 'GET',
        }).then(res => Promise.all([res.status, res.json()])).then(res => {
            let status = res[0];
            let data = res[1];

            if (status === 200) {
                setCourts(data.courts)
            }

            return {status, data}
        });
    }, [wi.uuid]);

    React.useEffect(() => {
        // Update state from new instance
        setDismissalDate(wi.data.dismissal_date ? dateFromMMDDYYYY(wi.data.dismissal_date) : defaultDate);
        setShowPetitionPreviewOverlay(false)
        setDriversNumber(wi.data.drivers_number)

        // // Get rap sheet data
        // fetch(API_URLS.getClientRapSheetData, {
        //     method: 'POST',
        //     headers: {"Content-Type": "application/json"},
        //     body: JSON.stringify({clientID: wi.client.uuid})
        // }).then( res => Promise.all([res.status, res.json()])).then(res => {
        //     let status = res[0];
        //     let data = res[1];
        //
        //     if (status === 200) {
        //         setRapData(data.extracted)
        //     }
        //
        //     return {status, data}
        // });
    }, [wi])

    function previewPetition() {
        fetch(API_URLS.runWorkflowAction, {
            method: 'POST',
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({instanceID: wi.uuid, action: "prepare_cr180"})
        }).then(res => Promise.all([res.status, res.json()])).then(res => {
            let status = res[0];
            let data = res[1];

            if (status === 200) {
                setPetitionFilePath(data.results.path)

                // Show overlay
                setShowPetitionPreviewOverlay(!showPetitionPreviewOverlay);
            }

            return {status, data}
        });
    }

    // TODO dedup
    function updateData(updates: any, logUpdates: any) {
        return fetch(API_URLS.updateWorkflowData, {
            method: 'POST',
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({
                instanceID: wi.uuid,
                updates: updates,
                logUpdates: logUpdates
            })
        }).then(res => Promise.all([res.status, res.json()])).then(res => {
            let status = res[0]
            let data = res[1]

            refresh()

            if (status === 200) {

            } else {
                alert("Could not update workflow data")  // TODO use global notifications for errors
            }

            return {status, data}
        });
    }

    function updateCR180Check(key: string, value: boolean) {
        // Make new flags
        let currentChecks = wi.data.cr180_checks || [];

        const checkCurrentlyIn = currentChecks.includes(key);

        if (value && !checkCurrentlyIn) {
            // Add check
            currentChecks.push(key)
        } else if (!value && checkCurrentlyIn) {
            // Remove check
            var index = currentChecks.indexOf(key);
            if (index !== -1) {
                currentChecks.splice(index, 1);
            }
        }

        updateData({cr180_checks: currentChecks}, {})
    }

    function updateCR180CheckMulti(kv: Array<{ key: string, value: boolean }>) {
        // Make new flags
        let currentChecks = wi.data.cr180_checks || [];

        for (const kvpair of kv) {
            const {key, value} = kvpair
            const checkCurrentlyIn = currentChecks.includes(key);

            if (value && !checkCurrentlyIn) {
                // Add check
                currentChecks.push(key)
            } else if (!value && checkCurrentlyIn) {
                // Remove check
                var index = currentChecks.indexOf(key);
                if (index !== -1) {
                    currentChecks.splice(index, 1);
                }
            }
        }

        updateData({cr180_checks: currentChecks}, {})
    }

    // @ts-ignore
    const workflowHandler = WorkflowHandlers[wi.workflow_name];

    const currentStepHandler = workflowHandler.steps[wi.current_step]

    const selectedCourt = wi.data.court && courts[wi.data.court];
    const county = selectedCourt && selectedCourt.county;

    // @ts-ignore
    return <Container className={'step-container'} fluid>
        <div className={'step-main-section'}>
            <h2>Petition Information</h2>
            <br/>

            <Form.Label className={'fw-700'}>Is the charge eligible for reduction to misdemeanor under Penal Code, §
                17(b)?</Form.Label>
            <Form.Check
                type="switch"
                id="pc17b-switch"
                label="Eligible under PC 17(b)"
                checked={wi.data.cr180_checks?.includes(checkOptions.pc17b)}
                onChange={(e) => {
                    updateCR180Check(checkOptions.pc17b, e.target.checked)
                }}
            />
            <br/>

            <Form.Label className={'fw-700'}>Is the charge eligible for reduction to infraction under Penal Code, §
                17(d)(2)?</Form.Label>
            <Form.Check
                type="switch"
                id="pc17d2-switch"
                label="Eligible under PC 17(d)(2)"
                checked={wi.data.cr180_checks?.includes(checkOptions.pc17d2)}
                onChange={(e) => {
                    updateCR180Check(checkOptions.pc17d2, e.target.checked)
                }}
            />
            <br/>

            <h5>{wi.data.remedy} Specific Questions</h5>

            {wi.data.remedy === "1203.4" && <>
                <Form.Label className={'fw-700'}>Has the client fulfilled the conditions of probation?</Form.Label>
                <Form.Check
                    type="switch"
                    id="twoa-switch"
                    label="Fulfilled probation conditions"
                    checked={wi.data.cr180_checks?.includes(checkOptions.twoa)}
                    onChange={(e) => {
                        updateCR180Check(checkOptions.twoa, e.target.checked)
                    }}
                />
                <br/>
                <Form.Label className={'fw-700'}>Has the client been discharged from probation prior to its
                    completion?</Form.Label>
                <Form.Check
                    type="switch"
                    id="twob-switch"
                    label="Discharged from probation before completion"
                    checked={wi.data.cr180_checks?.includes(checkOptions.twob)}
                    onChange={(e) => {
                        updateCR180Check(checkOptions.twob, e.target.checked)
                    }}
                />
                <br/>
            </>}

            {wi.data.remedy === "1203.4a" && <>
                <p>No additional questions required for 1203.4a</p>
            </>}

            {wi.data.remedy === "1203.41" && <>
                <Form.Label className={'fw-700'}>Sentence Completion</Form.Label>

                <Form.Select
                    value={
                        wi.data.cr180_checks?.includes(checkOptions.fivea)
                            ? checkOptions.fivea
                            : wi.data.cr180_checks?.includes(checkOptions.fiveb)
                                ? checkOptions.fiveb
                                : wi.data.cr180_checks?.includes(checkOptions.fivec)
                                    ? checkOptions.fivec
                                    : ""
                    }
                    onChange={(e) => {
                        const checks = [
                            {key: checkOptions.fivea, value: checkOptions.fivea === e.target.value},
                            {key: checkOptions.fiveb, value: checkOptions.fiveb === e.target.value},
                            {key: checkOptions.fivec, value: checkOptions.fivec === e.target.value},
                        ]

                        updateCR180CheckMulti(checks);
                    }}
                >
                    <option value={""}>(Choose One)</option>
                    <option value={checkOptions.fivea}>1 year since client completed felony jail sentence, with
                        mandatory supervision
                    </option>
                    <option value={checkOptions.fiveb}>2 years since client completed felony jail sentence, without
                        mandatory supervision
                    </option>
                    <option value={checkOptions.fivec}>2 years since client completed felony prison sentence, and
                        conviction did not require registration as a sex offender
                    </option>
                </Form.Select>
                <br/>
            </>}

            {wi.data.remedy === "1203.42" && <>
                <p>No additional questions required for 1203.42</p>
            </>}

            {wi.data.remedy === "1203.43" && <>

                <Form.Label className={'fw-700'}>Charge Dismissal Date</Form.Label>
                <br/>
                <DatePicker selected={dismissalDate} onChange={(newVal: Date) => {
                    setDismissalDate(newVal);
                    updateData({"dismissal_date": dateToMMDDYYYY(newVal)}, {})
                }}/>
                <br/>
                <br/>

                <Form.Label className={'fw-700'}>Case Resolution</Form.Label>

                <Form.Select
                    value={
                        wi.data.cr180_checks?.includes(checkOptions.sevena)
                            ? checkOptions.sevena
                            : wi.data.cr180_checks?.includes(checkOptions.sevenb1)
                                ? checkOptions.sevenb1
                                : wi.data.cr180_checks?.includes(checkOptions.sevenb2)
                                    ? checkOptions.sevenb2
                                    : ""
                    }
                    onChange={(e) => {
                        const checks = [
                            {key: checkOptions.sevena, value: checkOptions.sevena === e.target.value},
                            {key: checkOptions.sevenb1, value: checkOptions.sevenb1 === e.target.value},
                            {key: checkOptions.sevenb2, value: checkOptions.sevenb2 === e.target.value},
                        ]

                        updateCR180CheckMulti(checks);
                    }}
                >
                    <option value={""}>(Choose One)</option>
                    <option value={checkOptions.sevena}>Court records are available showing the case resolution</option>
                    <option value={checkOptions.sevenb1}>Charges dismissed after completion of requirements
                        for deferred entry of judgment, rap sheet attached
                    </option>
                    <option value={checkOptions.sevenb2}>Charges dismissed after completion of requirements
                        for deferred entry of judgment, rap sheet NOT attached
                    </option>
                </Form.Select>
                <br/>
            </>}

            {wi.data.remedy === "1203.49" && <>
                <p>No additional questions required for 1203.49</p>
            </>}


            {/* TODO Standardize */}
            {county === "San Diego" && <>
                <h5>{county} Specific Questions</h5>
                {/*TODO SSN*/}
                <h5>Driver's License Number</h5>
                <Form.Control
                    type={"text"}
                    placeholder="License Number"
                    value={driversNumber}
                    onChange={(e) => setDriversNumber(e.target.value)}
                    onBlur={() => updateData({"drivers_number": driversNumber}, {})}
                />
                <br/>
            </>}


            <Button className={'show-petition-btn'} variant={'outline-primary'} onClick={() => {
                showPetitionPreviewOverlay ? setShowPetitionPreviewOverlay(false) : previewPetition()
            }}>
                {showPetitionPreviewOverlay ? "Hide Petition" : "Preview Petition"}
            </Button>

        </div>
        <div className={'workflow-checkist-bar'}>
            <CA12034DataView wi={wi}/>
            <StepChecklist wi={wi} tasks={currentStepHandler.checkTasks} refresh={refresh}/>
        </div>

        {wi && showPetitionPreviewOverlay && petitionFilePath && <div className={'rap-overlay'}>
            <CloseButton onClick={() => setShowPetitionPreviewOverlay(false)}/>
            <strong className={'ms-2'}>PREVIEW - Changes will not be saved</strong>
            <div className={'rap-overlay-contents'}>
                <WorkflowDocView path={petitionFilePath} instanceID={wi.uuid}/>
            </div>
        </div>}

    </Container>
}

export function validateDeclarationStep(wi: WorkflowInstance<any>): { valid: boolean, msg: string } {
    if (wi.data.declaration_required && !wi.data.declaration_path) {
        return {valid: false, msg: "Missing uploaded declaration"}
    }

    return {valid: true, msg: ""}
}

function DeclarationDraftView({wi, refresh}: { wi: WorkflowInstance<any>, refresh: any }) {
    const [declarationFields, setDeclarationFields] = React.useState([]);
    const [declarationFieldValues, setDeclarationFieldValues] = React.useState<{ [key: string]: any; }>(wi.data.declaration_generation_answers || {});
    const [interviewFieldValues, setInterviewFieldValues] = React.useState<{ [key: string]: any; }>(wi.data.declaration_interview_answers || {});
    const [declarationParagraphs, setDeclarationParagraphs] = React.useState([]);
    const [isDrafting, setIsDrafting] = React.useState(false);
    const [isExtracting, setIsExtracting] = React.useState(false);
    const [rapData, setRapData] = React.useState<RapSheetExtractedData | null>(null)

    // Relevant History
    const convictionDateRaw = (wi as WorkflowInstance<CA12034Data>).data.conviction_date;
    const convictionDate = convictionDateRaw ? dateFromMMDDYYYY(convictionDateRaw) : "";
    const allCases = rapData?.cases
    const arrestsSinceConviction = (allCases?.filter((cse) => dateFromMMDDYYYY(cse.arrest_date) >= convictionDate)) || [];

    let convictedCountsSinceConviction: any[] = [];

    for (const arrest of arrestsSinceConviction) {
        for (const [num, count] of Object.entries(arrest.counts)) {
            if (count.convicted) {
                const convInfo = {
                    conviction_type: count.conviction_type,
                    convicted_on: count.convicted_on,
                    charge_name: count.charge_name,
                    arrest_address: count.arrest_address,
                }

                convictedCountsSinceConviction.push(convInfo)
            }
        }
    }

    const workflowDefaultValues = {
        name: `${wi.client.name.firstName} ${wi.client.name.lastName}`,
        date_of_birth: wi.client.dateOfBirth ? dateFromMMDDYYYY(wi.client.dateOfBirth) : new Date(),
        arrest_charge: wi.data.charge_name,
        charge_type: wi.data.conviction_type,
        sentence_date: wi.data.conviction_date ? dateFromMMDDYYYY(wi.data.conviction_date) : new Date(),
        convictions_since: convictedCountsSinceConviction,
    }

    // TODO dedup
    function updateData(updates: any, logUpdates: any) {
        return fetch(API_URLS.updateWorkflowData, {
            method: 'POST',
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({
                instanceID: wi.uuid,
                updates: updates,
                logUpdates: logUpdates
            })
        }).then(res => Promise.all([res.status, res.json()])).then(res => {
            let status = res[0]
            let data = res[1]

            refresh()

            if (status === 200) {

            } else {
                alert("Could not update workflow data")  // TODO use global notifications for errors
            }

            return {status, data}
        });
    }

    React.useEffect(() => {
        // Get rap sheet data
        fetch(API_URLS.getClientRapSheetData, {
            method: 'POST',
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({clientID: wi.client.uuid})
        }).then(res => Promise.all([res.status, res.json()])).then(res => {
            let status = res[0];
            let data = res[1];

            if (status === 200) {
                setRapData(data.extracted)
            }

            return {status, data}
        });
    }, [wi.uuid])

    React.useEffect(() => {
        setDeclarationFieldValues({...(wi.data.declaration_generation_answers || {}), ...workflowDefaultValues})
        setInterviewFieldValues(wi.data.declaration_interview_answers || {})

        fetch(API_URLS.getDeclarationFields, {
            method: 'GET',
        }).then(res => Promise.all([res.status, res.json()])).then(res => {
            let status = res[0]
            let data = res[1]

            if (status === 200) {
                setDeclarationFields(data.fields)

                // let defaultValMapping = {};
                // // data.fields.forEach((obj: DynamicFieldProps) => {
                // //     @ts-ignore
                // // defaultValMapping[obj.name] = obj.defaultValue;
                // // });
                //
                // defaultValMapping = {...defaultValMapping, ...workflowDefaultValues}
                //
                // setDeclarationFieldValues(defaultValMapping)
            }

            return {status, data}
        });
    }, [wi, rapData])

    function draftDeclaration() {
        setIsDrafting(true)

        fetch(API_URLS.runWorkflowAction, {
            method: 'POST',
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({
                instanceID: wi.uuid,
                action: "prepare_declaration",
                kwargs: {fields: declarationFieldValues}
            })
        }).then(res => Promise.all([res.status, res.json()])).then(res => {
            let status = res[0]
            let data = res[1]

            if (status === 200) {
                const fileID = data.results.id;
                window.open(`https://docs.google.com/document/d/${fileID}/edit`, "_blank")
                // setDeclarationParagraphs(data.declaration)
                refresh()
                setIsDrafting(false)
            }

            return {status, data}
        });
    }

    function extractFieldsFromNotes() {
        setIsExtracting(true)

        fetch(API_URLS.runWorkflowAction, {
            method: 'POST',
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({
                instanceID: wi.uuid,
                action: "extract_declaration_fields_from_notes",
                kwargs: {fields: declarationFieldValues, notes: interviewFieldValues}
            })
        }).then(res => Promise.all([res.status, res.json()])).then(res => {
            let status = res[0]
            let data = res[1]

            if (status === 200) {
                refresh()
                setIsExtracting(false)


                // Save to workflow
                if (data.results.fields) {
                    setDeclarationFieldValues(data.results.fields)
                    updateData({"declaration_generation_answers": data.results.fields}, {"declaration_generation_answers": ""})
                }
            }

            return {status, data}
        });
    }

    function handleFieldUpdate(fieldName: string, newVal: any) {
        // Copy
        let newFieldVals = JSON.parse(JSON.stringify(declarationFieldValues))

        // Add new value
        newFieldVals[fieldName] = newVal;

        // Update state
        setDeclarationFieldValues(newFieldVals)

        // Save to workflow
        updateData({"declaration_generation_answers": newFieldVals}, {"declaration_generation_answers": ""})
    }

    function handleInterviewFieldUpdate(fieldName: string, newVal: any, dontSave?: boolean) {
        // Copy
        let newFieldVals = JSON.parse(JSON.stringify(interviewFieldValues))

        // Add new value
        newFieldVals[fieldName] = newVal;

        // Update state
        setInterviewFieldValues(newFieldVals)

        // Save to workflow
        if (!dontSave) {
            updateData({"declaration_interview_answers": newFieldVals}, {"declaration_interview_answers": ""})
        }
    }


    const interviewQuestions = {
        0: "Can you please describe the nature and circumstances of the offense? What do you remember about that day?",
        10: "Is there anything in your family or circumstances growing up that related in any way to how you acted in this situation?  Do you take responsibility for your actions that day? ",
        20: "How have you moved forward in your life since these convictions? ",
        30: "Are you currently employed? How long have you been working here? ",
        40: "Do you do any work in the community or volunteer? ",
        50: "Do you participate in any religious organizations or related activities? ",
        60: "Do you take care of any family? ",
        70: "If didn’t complete probation: Can you share any information on why you were not able to complete probation, or community service or fine (speak to what is relevant). ",
        80: "Since probation, have you had any encounters with the law?",
        90: "What have you done to rehabilitate yourself and turn your life around? When did you make the change and any additional details you can share about the new path you are on.   ",
        100: "What would getting this expunged and sealed on your record do for you? Why is it important to you? (foster parent, employment, housing, etc.)",
        110: "Where will you be when you sign this, ex: Los Angeles, California",
        120: "Additional information"
    }

    return (<React.Fragment>
            <Row className={'drafting-main'}>
                <Col className={'drafting-notes'}>
                    <h5>Interview Questions</h5>
                    {
                        Object.entries(interviewQuestions).map(([idx, question]) => <div className={"declaration-interview-question"}>
                            <Form.Label>{question}</Form.Label>
                            <Form.Control as="textarea" rows={5} value={interviewFieldValues[idx]} onChange={(e: any) => handleInterviewFieldUpdate(idx, e.target.value, true)} onBlur={(e: any) => handleInterviewFieldUpdate(idx, e.target.value)}/>
                        </div>)
                    }
                </Col>
                <Col xs lg="5" className={'drafting-fields'}>
                    <h5>Drafting Template Fields</h5>
                    <div className="workflow-declaration-form">
                        {declarationFields.map((df: DynamicFieldProps) => !(df.name in workflowDefaultValues) && (!df['conditionalRender'] || declarationFieldValues[df['conditionalRender']]) &&
                            <DynamicField {...{...df, defaultValue: null}}
                                            value={declarationFieldValues[df.name]}
                                          onChange={(newVal: any) => handleFieldUpdate(df.name, newVal)}/>)}
                        <br/>
                    </div>
                </Col>
            </Row>
            <Row className={'drafting-footer'}>
                <Button size={'sm'} disabled={isExtracting} onClick={() => !isExtracting ? extractFieldsFromNotes() : null}>{isExtracting ? "Extracting..." : "Extract Fields from Notes"}</Button>
                <Button size={'sm'} disabled={isDrafting} onClick={() => !isDrafting ? draftDeclaration() : null}>{isDrafting ? "Drafting..." : "Draft!"}</Button>
            </Row>

            {/*<div className={"workflow-declaration-text"}>*/}
            {/*    {declarationParagraphs.length === 0 && <em>Press the "Draft" button to draft a declaration</em>}*/}
            {/*    {declarationParagraphs.length > 0 &&*/}
            {/*        <Button className={'copy-button'} variant={'outline-success'} onClick={() => {*/}
            {/*            navigator.clipboard.writeText(declarationParagraphs.join("\n\n"))*/}
            {/*        }}>Copy to Clipboard</Button>}*/}
            {/*    {declarationParagraphs.map((p) => <p>{p}</p>)}*/}
            {/*</div>*/}
        </React.Fragment>
    );
}

export function DeclarationStep({wi, refresh}: StepComponentProps) {
    const [showDrafter, setShowDrafter] = React.useState(false);
    const [showDrafterFullScreen, setShowDrafterFullScreen] = React.useState(true);
    const [showUpload, setShowUpload] = React.useState(wi.data.declaration_path ? false : true);
    const [showDeleteModal, setShowDeleteModal] = React.useState(false);
    const [showDeclarationOverlay, setShowDeclarationOverlay] = React.useState(false);
    const [isImporting, setIsImporting] = React.useState(false);

    React.useEffect(() => {
        setShowUpload(wi.data.declaration_path ? false : true)
        setShowDeclarationOverlay(false)
        setShowDrafter(false)
        // setShowDrafterFullScreen(false)
        setIsImporting(false)
        setShowDeleteModal(false)

    }, [wi.uuid])

    const handleUploadFileChange = (e: any) => {
        const file: File = e.target.files[0];

        if (!file) {
            return
        }

        if (!file.name.endsWith(".pdf")) {
            alert("PDF is required for declaration")
            return
        }

        // Add file to form data
        const data = new FormData();
        data.append('file', file, file.name);
        data.append('instanceID', wi.uuid);
        data.append('fileName', "declaration.pdf");

        // Make request
        fetch(API_URLS.uploadWorkflowFile, {
            method: 'POST',
            body: data
        }).then(res => Promise.all([res.status, res.json()])).then(res => {
            let status = res[0]
            let data = res[1]

            if (status === 200) {
                // Save case print path
                updateData({"declaration_path": data.path}, {"declaration_path": ""}).then(() => refresh());
            }
        })
    };

    // TODO dedup
    function updateData(updates: any, logUpdates: any) {
        return fetch(API_URLS.updateWorkflowData, {
            method: 'POST',
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({
                instanceID: wi.uuid,
                updates: updates,
                logUpdates: logUpdates
            })
        }).then(res => Promise.all([res.status, res.json()])).then(res => {
            let status = res[0]
            let data = res[1]

            refresh()

            if (status === 200) {

            } else {
                alert("Could not update workflow data")  // TODO use global notifications for errors
            }

            return {status, data}
        });
    }

    function importDeclarationFromDrive() {
        setIsImporting(true)

        fetch(API_URLS.runWorkflowAction, {
            method: 'POST',
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({
                instanceID: wi.uuid,
                action: "import_finished_declaration_from_drive",
            })
        }).then(res => Promise.all([res.status, res.json()])).then(res => {
            let status = res[0]
            let data = res[1]

            if (status === 200) {
                refresh()
                setIsImporting(false)
            }

            return {status, data}
        });
    }

    // @ts-ignore
    const workflowHandler = WorkflowHandlers[wi.workflow_name];

    const currentStepHandler = workflowHandler.steps[wi.current_step]

    // @ts-ignore
    return <Container className={'step-container'} fluid>
        <div className={'step-main-section'}>
            <h2>Declaration</h2>

            {wi.data.declaration_required !== undefined && <Badge bg={wi.data.declaration_required ? "danger" : "success"}>
                {wi.data.remedy} {wi.data.relief_type}:
                Declaration {wi.data.declaration_required ? "required" : "not required"}
            </Badge>}

            <br/>
            <br/>
            <h5>Declaration PDF</h5>
            {wi.data.declaration_path && <>
                <Button className={'show-petition-btn me-3'} variant={'success'} onClick={() => {
                    setShowDeclarationOverlay(!showDeclarationOverlay)
                }}>
                    {showDeclarationOverlay ? "Hide Declaration" : "View Declaration"}
                </Button>
            </>}

            {showUpload
                ? <Form.Control className={'mt-3'} type="file" onChange={handleUploadFileChange}/>
                :
                <Button variant={'outline-primary me-3'} onClick={() => setShowUpload(true)}>Upload New Declaration</Button>
            }

            {wi.data.declaration_path && <Button variant={'outline-danger'} onClick={() => setShowDeleteModal(true)}>Remove Declaration</Button>}

            {
                wi.data.declaration_draft_drive_id && <>
                    <br/>
                    <br/>
                    <Button className={'me-3'} variant={'success'}
                            onClick={() => window.open(`https://docs.google.com/document/d/${wi.data.declaration_draft_drive_id}/edit`, "_blank")}>View
                        Draft in Drive</Button>
                    <Button variant={'outline-primary'} disabled={isImporting}
                            onClick={() => !isImporting ? importDeclarationFromDrive() : null}>{isImporting ? "Importing..." : "Import Finished from Drive"}</Button>
                </>
            }

            <br/>
            <hr/>
            <Button  variant={"outline-success"} onClick={() => {
                        setShowDrafter(!showDrafter);
                        // setShowDrafterFullScreen(false);
                    }}>Launch Drafting View</Button>
            {showDrafter && <div className={`declaration-drafter-section ${showDrafterFullScreen ? "fullscreen" : ""}`}>
                <div className={'declaration-drafter-heading'}>
                    <h5>Declaration Drafting – {`${wi.client.name.firstName} ${wi.client.name.lastName}`}</h5>
                    {/*{showDrafter && <Button size={'sm'} variant={showDrafterFullScreen ? "outline-secondary" : "outline-success"} onClick={() => setShowDrafterFullScreen(!showDrafterFullScreen)}>{showDrafterFullScreen ? "Exit Fullscreen" : "Fullscreen"}</Button>}*/}
                    <Button size={'sm'} variant={showDrafter ? "outline-danger" : "outline-primary"} onClick={() => {
                        setShowDrafter(!showDrafter);
                        // setShowDrafterFullScreen(false);
                    }}>{showDrafter ? "Hide" : "Show"}</Button>
                </div>
                <DeclarationDraftView wi={wi} refresh={refresh}/>
            </div>}

        </div>
        <div className={'workflow-checkist-bar'}>
            <CA12034DataView wi={wi}/>
            <StepChecklist wi={wi} tasks={currentStepHandler.checkTasks} refresh={refresh}/>
        </div>

        {wi && showDeclarationOverlay && <div className={'rap-overlay'}>
            <CloseButton onClick={() => setShowDeclarationOverlay(false)}/>
            <div className={'rap-overlay-contents'}>
                <WorkflowDocView path={wi.data.declaration_path} instanceID={wi.uuid}/>
            </div>
        </div>}

        <Modal show={showDeleteModal} onHide={() => {
            setShowDeleteModal(false)
        }}>
            <Modal.Header closeButton>
                <Modal.Title>Remove Declaration?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    Client: <strong>{wi.client.name.firstName} {wi.client.name.lastName}</strong>
                </div>
                <br/>
                <Button type="submit" variant="danger" onClick={() => {
                    updateData({"declaration_path": null}, {"declaration_path": ""}).then(() => refresh());
                }}>
                        Remove
                </Button>
            </Modal.Body>
        </Modal>
    </Container>
}

function validateProofOfServiceStep(wi: WorkflowInstance<any>): { valid: boolean, msg: string } {
    if (!wi.data.service_id) {
        return {valid: false, msg: "Missing office to serve to"}
    }
    if (!wi.data.service_method) {
        return {valid: false, msg: "Missing service method"}
    }

    return {valid: true, msg: ""}
}

export type DALogicRule = {
    county: string | null,
    city: string | null,
    arresting_agency: string | null,
    court: string | null,
    type_of_crime: string | null,
    serve_id: number | null,
}

export type DAContact = {
    id: number | null,
    county: string | null,
    agency: string | null,
    full_name: string | null,
    branch: string | null,
    address_line1: string | null,
    address_line2: string | null,
    city: string | null,
    state: string | null,
    zipcode: string | null,
    phone_number: string | null,
    fax_number: string | null,
    backup_fax_number: string | null,
    email_address: string | null,
    serve_email: boolean | null,
    serve_fax: boolean | null,
    serve_mail: boolean | null,
}

function DAContactCard({c, onSelect, sm}: { c: DAContact, onSelect?: any, sm?: boolean }) {
    return <Card className={'court-contact-card'}>
        <Card.Body>
            <Card.Title>
                {c.county} County - {c.full_name}{c.branch && ", "}{c.branch}
                {onSelect && <Button size={'sm'} variant={'success'} onClick={() => onSelect(c)}>Select</Button>}
            </Card.Title>
            {!sm && <Card.Text>
                {c.address_line1} {c.address_line2}
                <br/>
                {c.city} {c.state} {c.zipcode}
                <br/>
                <br/>
                {c.email_address && <>Email: {c.email_address}<br/></>}
                {c.fax_number && <>Fax: {c.fax_number}<br/></>}
                {c.backup_fax_number && <>Backup Fax: {c.backup_fax_number}<br/></>}
                Serve by:&nbsp;
                {c.serve_mail && <Badge>Mail</Badge>}
                {c.serve_email && <Badge>Email</Badge>}
                {c.serve_fax && <Badge>Fax</Badge>}
            </Card.Text>}
        </Card.Body>
    </Card>
}

function ProofOfServiceStep({wi, refresh}: StepComponentProps) {
    const defaultDate = new Date();

    const [das, setDas] = React.useState<{ [k: number]: DAContact }>({})
    const [sortedDALogicMatches, setSortedDALogicMatches] = React.useState<Array<{id: number, score: number }>>([])
    const [rapData, setRapData] = React.useState<RapSheetExtractedData | null>(null)
    const [courts, setCourts] = React.useState<{ [k: string]: CourtContact }>({})

    const [showPreviewOverlay, setShowPreviewOverlay] = React.useState<boolean>(false);
    const [proofOfServiceFilePath, setProofOfServiceFilePath] = React.useState<string | null>(wi.data.proof_of_service_path);
    const [serviceMethod, setServiceMethod] = React.useState<string>((wi as WorkflowInstance<CA12034Data>).data.service_method || "")
    // const [serviceDate, setServiceDate] = React.useState(wi.data.service_date ? dateFromMMDDYYYY(wi.data.service_date) : defaultDate);
    // const [serviceByName, setServiceByName] = React.useState(wi.data.service_by_name);
    // const [serviceByCity, setServiceByCity] = React.useState(wi.data.service_by_city);
    // const [serviceByState, setServiceByState] = React.useState(wi.data.service_by_state);

    const [daQuery, setDAQuery] = React.useState<string>("")

    function fuzzySearchDAs(query: string, n: number): DAContact[] {
        let scores: { da: DAContact, score: number }[] = [];

        for (const da of Object.values(das)) {
            const score = stringSimilarity(query, `${da.full_name} ${da.branch}`);
            scores.push({da, score});
        }

        scores.sort((a, b) => b.score - a.score);

        return scores.slice(0, n).map(cs => cs.da);
    }

    function handleSelectDAContact(c: DAContact) {
        // Use best option for service method
        let method = null;

        if (c.serve_email) {
            method = "email"
        } else if (c.serve_fax) {
            method = "fax"
        } else {
            method = "mail"
        }

        updateData({
            "service_id": c.id,
            "service_method": method,
            "service_use_backup_fax": false,
        }, {
            "service_id": `${c.county} County - ${c.full_name}${c.branch ? ', ' : ''}${c.branch}`
        })
        setDAQuery("")
    }

    React.useEffect(() => {
        // Update state from new instance
        setProofOfServiceFilePath(wi.data.proof_of_service_path || "")
        setShowPreviewOverlay(false)
        setServiceMethod(wi.data.service_method || "")
        // setServiceDate(wi.data.service_date ? dateFromMMDDYYYY(wi.data.service_date) : defaultDate);
        // setServiceByName(wi.data.service_by_name || "")
        // setServiceByCity(wi.data.service_by_city || "")
        // setServiceByState(wi.data.service_by_state || "")
    }, [wi])

    React.useEffect(() => {
        // Get rap sheet data
        fetch(API_URLS.getClientRapSheetData, {
            method: 'POST',
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({clientID: wi.client.uuid})
        }).then(res => Promise.all([res.status, res.json()])).then(res => {
            let status = res[0];
            let data = res[1];

            if (status === 200) {
                setRapData(data.extracted)
            }

            return {status, data}
        });

        fetch(API_URLS.getDADirectory, {
            method: 'GET',
        }).then(res => Promise.all([res.status, res.json()])).then(res => {
            let status = res[0];
            let data = res[1];

            if (status === 200) {
                setDas(data.das)
                // setDaLogicRules(data.da_rules)
                setCourts(data.courts)
            }

            return {status, data}
        });

        fetch(API_URLS.runWorkflowAction, {
            method: 'POST',
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({instanceID: wi.uuid, action: "find_best_da_matches"})
        }).then(res => Promise.all([res.status, res.json()])).then(res => {
            let status = res[0];
            let data = res[1];

            if (status === 200) {
                setSortedDALogicMatches(data.results)
            }

            return {status, data}
        });
    }, [wi.uuid])

    function previewProofOfService() {
        fetch(API_URLS.runWorkflowAction, {
            method: 'POST',
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({instanceID: wi.uuid, action: "prepare_proof_of_service"})
        }).then(res => Promise.all([res.status, res.json()])).then(res => {
            let status = res[0];
            let data = res[1];

            if (status === 200) {
                setProofOfServiceFilePath(data.results.path)

                // Show overlay
                setShowPreviewOverlay(true);
            }

            return {status, data}
        });
    }

    // TODO dedup
    function updateData(updates: any, logUpdates: any) {
        return fetch(API_URLS.updateWorkflowData, {
            method: 'POST',
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({
                instanceID: wi.uuid,
                updates: updates,
                logUpdates: logUpdates
            })
        }).then(res => Promise.all([res.status, res.json()])).then(res => {
            let status = res[0]
            let data = res[1]

            refresh()

            if (status === 200) {

            } else {
                alert("Could not update workflow data")  // TODO use global notifications for errors
            }

            return {status, data}
        });
    }


    // @ts-ignore
    const workflowHandler = WorkflowHandlers[wi.workflow_name];

    const currentStepHandler = workflowHandler.steps[wi.current_step]

    const selectedDA = das[wi.data.service_id];
    const selectedCourt = courts[wi.data.court];


    // Get arresting agency
    const selectedCase = rapData && rapData.cases.filter(c => c.uuid === wi.data.case_uuid)[0];
    const selectedCountNumber = (wi as WorkflowInstance<CA12034Data>).data.count_number;
    const selectedCount = selectedCountNumber && selectedCase?.counts[selectedCountNumber as string] as ExtractedCount;
    const selectedCaseArrestAgency = selectedCase?.arrest_agency || (selectedCount && selectedCount.arrest_by);
    const typeOfCrime = (selectedCount && selectedCount.type_of_crime) || wi.data.conviction_type;

    // @ts-ignore
    return <Container className={'step-container'} fluid>
        <div className={'step-main-section'}>
            <h2>Proof Of Service</h2>
            <br/>
            <h5>Relevant Case Info</h5>
            {selectedCourt && <><strong>County:</strong>{selectedCourt.county}<br/></>}
            <strong>Type of Crime:</strong> {typeOfCrime}<br/>
            <strong>Court:</strong> {wi.data.court}<br/>
            {selectedCaseArrestAgency && <><strong>Arresting Agency:</strong> {selectedCaseArrestAgency}<br/></>}
            <br/>

            {
                sortedDALogicMatches.length > 0
                && sortedDALogicMatches[0].score === 1
                && das[sortedDALogicMatches[0].id]
                && <>
                    <h5>Recommended</h5>
                    <DAContactCard c={das[sortedDALogicMatches[0].id]} onSelect={sortedDALogicMatches[0].id !== wi.data.service_id ? handleSelectDAContact: null} sm={true}/>
                    <br/>
                </>
            }

            <h5>Search for District/City Attorney</h5>
            <Form.Control
                type={"text"}
                placeholder="Name"
                value={daQuery}
                onChange={(e) => setDAQuery(e.target.value)}
            />
            <br/>

            <div className={'court-contacts'}>
                {daQuery && fuzzySearchDAs(daQuery, 5).map((c) => <DAContactCard c={c} onSelect={handleSelectDAContact} sm={true}/>)}
            </div>
            {/*<br/>*/}

            {selectedDA && <>
                <h5>Selected</h5>
                <DAContactCard c={selectedDA}/>
                <br/>
                <h5>Service Method</h5>
                <Form.Select value={serviceMethod} onChange={(e) => {
                    setServiceMethod(e.target.value);
                    updateData({"service_method": e.target.value}, {})
                }}>
                    {/*<option value={""}>-</option>*/}
                    {selectedDA.serve_email && <option value={"email"}>Email</option>}
                    {selectedDA.serve_fax && <option value={"fax"}>Fax</option>}
                    {selectedDA.serve_mail && <option value={"mail"}>Mail</option>}
                </Form.Select>
                <br/>

                {selectedDA.backup_fax_number && wi.data.service_method == "fax" && <>
                    <Form.Check
                        type="switch"
                        id="backup-fax-switch"
                        label="Use backup fax number"
                        checked={wi.data.service_use_backup_fax}
                        onChange={(e) => {
                            updateData({"service_use_backup_fax": e.target.checked}, {})
                        }}
                    />
                </>}

            </>}

            <hr/>

            {/*<h5>Service Date</h5>*/}
            {/*    <DatePicker selected={serviceDate} onChange={(newVal: Date) => {*/}
            {/*        setServiceDate(newVal);*/}
            {/*        updateData({"service_date": dateToMMDDYYYY(newVal)}, {})*/}
            {/*}}/>*/}
            {/*<br/>*/}
            {/*<br/>*/}

            {/*<h5>Service By</h5>*/}
            {/*<Form.Label>Name</Form.Label>*/}
            {/*<Form.Control*/}
            {/*    type={"text"}*/}
            {/*    placeholder="Service By Name"*/}
            {/*    value={serviceByName}*/}
            {/*    onChange={(e) => setServiceByName(e.target.value)}*/}
            {/*    onBlur={() => updateData({"service_by_name": serviceByName}, {})}*/}
            {/*/>*/}
            {/*<br/>*/}
            {/*<Form.Label>City</Form.Label>*/}
            {/*<Form.Control*/}
            {/*    type={"text"}*/}
            {/*    placeholder="Service By City"*/}
            {/*    value={serviceByCity}*/}
            {/*    onChange={(e) => setServiceByCity(e.target.value)}*/}
            {/*    onBlur={() => updateData({"service_by_city": serviceByCity}, {})}*/}
            {/*/>*/}
            {/*<br/>*/}
            {/*<Form.Label>State</Form.Label>*/}
            {/*<Form.Control*/}
            {/*    type={"text"}*/}
            {/*    placeholder="Service By State"*/}
            {/*    value={serviceByState}*/}
            {/*    onChange={(e) => setServiceByState(e.target.value)}*/}
            {/*    onBlur={() => updateData({"service_by_state": serviceByState}, {})}*/}
            {/*/>*/}
            {/*<br/>*/}

            <br/>

            <Button className={'show-petition-btn'} variant={'outline-primary'} onClick={() => {
                showPreviewOverlay ? setShowPreviewOverlay(false) : previewProofOfService()
            }}>
                {showPreviewOverlay ? "Hide Preview" : "Preview Proof of Service"}
            </Button>

        </div>
        <div className={'workflow-checkist-bar'}>
            <CA12034DataView wi={wi}/>
            <StepChecklist wi={wi} tasks={currentStepHandler.checkTasks} refresh={refresh}/>
        </div>

        {wi && showPreviewOverlay && proofOfServiceFilePath && <div className={'rap-overlay'}>
            <CloseButton onClick={() => setShowPreviewOverlay(false)}/>
            <strong className={'ms-2'}>PREVIEW - Changes will not be saved</strong>
            <div className={'rap-overlay-contents'}>
                <WorkflowDocView path={proofOfServiceFilePath} instanceID={wi.uuid}/>
            </div>
        </div>}
    </Container>
}


function FinalReviewStep({wi, refresh}: StepComponentProps) {
    const [showOverlay, setShowOverlay] = React.useState("");
    const [regenerating, setRegenerating] = React.useState(false);

    React.useEffect(() => {
        setShowOverlay("")
    }, [wi.uuid])

    function regenerateFiles() {
        setRegenerating(true)
        setShowOverlay("")

        fetch(API_URLS.runWorkflowAction, {
            method: 'POST',
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({instanceID: wi.uuid, action: "prepare_all_documents"})
        }).then(res => Promise.all([res.status, res.json()])).then(res => {
            let status = res[0];
            let data = res[1];

            if (status === 200) {
                refresh()
                setRegenerating(false)
                setShowOverlay("")
            }

            return {status, data}
        });
    }

    // @ts-ignore
    const workflowHandler = WorkflowHandlers[wi.workflow_name];

    const currentStepHandler = workflowHandler.steps[wi.current_step]

    let filesToShow = [  // TODO Standardize for extra files
        {
            name: "Petition for Dismissal CR-180",
            id: "cr180",
            path: wi.data.petition_path,
        },
        {
            name: "Order for Dismissal CR-181",
            id: "cr181",
            path: wi.data.order_path,
        },
        {
            name: "Declaration",
            id: "declaration",
            path: wi.data.declaration_path,
        },
        {
            name: "Proof of Service",
            id: "proof",
            path: wi.data.proof_of_service_path,
        },
        {
            name: "Service Fax Cover Sheet",
            id: "serve-fax-cover",
            path: wi.data.service_cover_path,
        },
    ];

    if (wi.data.county_specific_path) {
        filesToShow.push({
            name: "San Diego Work Up Sheet",  // TODO
            id: "county-specific",
            path: wi.data.county_specific_path,
        });
    }

    return <Container className={'step-container'} fluid>
        <div className={'step-main-section'}>
            <h2>Final Review</h2>
            <br/>

            <Button variant={'outline-success'} disabled={regenerating} onClick={regenerateFiles}>{regenerating ? "Regenerating..." : "Regenerate All Files"}</Button>
            <br/>
            <br/>
            <h5>Generated Files</h5>

            <Table>
                <thead>
                    <tr>
                        <th>File</th>
                        <th>View</th>
                    </tr>
                </thead>
                <tbody>
                    {filesToShow.filter((f) => f.path && f.path.length > 0).map((f) => (<tr>
                        <td>{f.name}</td>
                        <td>
                            <Button size='sm' className={'show-petition-btn'} variant={'outline-primary'} onClick={() => {
                                showOverlay === f.id ? setShowOverlay("") : setShowOverlay(f.id)
                            }}>
                                {showOverlay === f.id ? "Hide" : "View"}
                            </Button>
                        </td>
                    </tr>))}
                </tbody>
            </Table>

        </div>

        {filesToShow.map((f) => (
            <>
                {wi && showOverlay === f.id && <div className={'rap-overlay'}>
                    <CloseButton onClick={() => setShowOverlay("")}/>
                    <strong className={'ms-2'}>PREVIEW - Changes will not be saved</strong>
                    <div className={'rap-overlay-contents'}>
                        <WorkflowDocView path={f.path} instanceID={wi.uuid}/>
                    </div>
                </div>}
            </>
        ))}


        <div className={'workflow-checkist-bar'}>
            <CA12034DataView wi={wi}/>
            <StepChecklist wi={wi} tasks={currentStepHandler.checkTasks} refresh={refresh}/>
        </div>
    </Container>
}

function ProjectFileUploadModal({wi, show, setShow, refresh}: {wi: WorkflowInstance<any>, show: boolean, setShow: any, refresh: any}) {
    const [data, setData] = React.useState(new FormData());
    const [readyToUpload, setReadyToUpload] = React.useState(false);

    React.useEffect(() => {
        setData(new FormData())
        setReadyToUpload(false)
    }, [wi.uuid])

    function handleFileChange(e: any){
        const file: File = e.target.files[0];

        if (!file) {
            return
        }

        // Add file to form data
        const newData = new FormData();
        newData.append('file', file, file.name);
        newData.append('instanceID', wi.uuid);
        newData.append('fileName', file.name);
        newData.append('trackUpload', "1");

        setData(newData)
        setReadyToUpload(true)
    }

    function doUpload(){
        // Make request
        fetch(API_URLS.uploadWorkflowFile, {
            method: 'POST',
            body: data,
        }).then(res => Promise.all([res.status, res.json()])).then(res => {
            let status = res[0]
            let data = res[1]

            if (status === 200) {
                setShow(false);
                refresh();
            }
        })
    }

    return <Modal show={show} onHide={() => {
            setShow(false)
        }}>
        <Modal.Header closeButton>
            <Modal.Title>Upload File</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div>
                Client: <strong>{wi.client.name.firstName} {wi.client.name.lastName}</strong>
            </div>
            <br/>
            <Form.Control type="file" onChange={handleFileChange}/>
            <br/>
            <Button type="submit" variant="success" disabled={!readyToUpload} onClick={doUpload}>
                    Upload
            </Button>
        </Modal.Body>
    </Modal>
}

function FinishedStep({wi, refresh, showTasks, showRegenerate}: {showTasks?: boolean, showRegenerate?: boolean} & StepComponentProps) {
    const navigate = useNavigate();

    const [showOverlay, setShowOverlay] = React.useState("");
    const [exporting, setExporting] = React.useState(false);
    const [showUploadModal, setShowUploadModal] = React.useState(false);
    const [regenerating, setRegenerating] = React.useState(false);

    React.useEffect(() => {
        setShowOverlay("")
    }, [wi.uuid])

    function regenerateFiles() {
        setRegenerating(true)
        setShowOverlay("")

        fetch(API_URLS.runWorkflowAction, {
            method: 'POST',
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({instanceID: wi.uuid, action: "prepare_all_documents"})
        }).then(res => Promise.all([res.status, res.json()])).then(res => {
            let status = res[0];
            let data = res[1];

            if (status === 200) {
                refresh()
                setRegenerating(false)
                setShowOverlay("")
            }

            return {status, data}
        });
    }

    function downloadFile(filepath: string, name: string) {
        fetch(API_URLS.getWorkflowFile, {
            method: 'POST',
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({
                instanceID: wi.uuid,
                path: filepath,
            })
        }).then( res => Promise.all([res.status, res.blob()])).then(res => {
            let status = res[0];
            let blob = res[1];

            if (status === 200) {
                // Download
                let csvURL = window.URL.createObjectURL(blob);
                let tempLink = document.createElement('a');
                tempLink.href = csvURL;
                tempLink.setAttribute('download', name);
                tempLink.click();
            }

            return {status, blob}
        });
    }

    function downloadAction(actionName: string, fileName: string) {
        setExporting(true);

        fetch(API_URLS.runWorkflowAction, {
            method: 'POST',
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({instanceID: wi.uuid, action: actionName})
        }).then( res => Promise.all([res.status, res.blob()])).then(res => {
            let status = res[0];
            let blob = res[1];

            if (status === 200) {
                // Download
                let csvURL = window.URL.createObjectURL(blob);
                let tempLink = document.createElement('a');
                tempLink.href = csvURL;
                tempLink.setAttribute('download', fileName);
                tempLink.click();
                setExporting(false);
            }

            return {status, blob}
        });
    }

    function draftSupplementalDeclaration() {
        fetch(API_URLS.runWorkflowAction, {
            method: 'POST',
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({instanceID: wi.uuid, action: "draft_supplemental_declaration"})
        }).then(res => Promise.all([res.status, res.json()])).then(res => {
            let status = res[0];
            let data = res[1];

            if (status === 200) {
                // Redirect
                navigate(APP_URLS.workflowSingleGenerator(data.results.workflow_name, data.results.uuid))
            }

            return {status, data}
        });
    }

    // @ts-ignore
    const workflowHandler = WorkflowHandlers[wi.workflow_name];

    const currentStepHandler = workflowHandler.steps[wi.current_step]

    let filesToShow = [
        {
            name: "Petition for Dismissal CR-180",
            id: "cr180",
            path: wi.data.petition_path,
        },
        {
            name: "Order for Dismissal CR-181",
            id: "cr181",
            path: wi.data.order_path,
        },
        {
            name: "Declaration",
            id: "declaration",
            path: wi.data.declaration_path,
        },
        {
            name: "Proof of Service",
            id: "proof",
            path: wi.data.proof_of_service_path,
        },
        {
            name: "Service Fax Cover Sheet",
            id: "serve-fax-cover",
            path: wi.data.service_cover_path,
        },
    ];

    if (wi.data.county_specific_path) {
        filesToShow.push({
            name: "San Diego Work Up Sheet",  // TODO
            id: "county-specific",
            path: wi.data.county_specific_path,
        });
    }

    const packetsToShow = [
        {
            name: "Service Packet",
            action: "export_service_packet",
        },
        {
            name: "Filing Packet",
            action: "export_filing_packet",
        },
    ];

    const uploadedFilesToShow = wi.uploadedFiles.map((fp: string) => {return {
            name: fp.split('/').pop(),
            id: fp,
            path: fp,
    }});

    // @ts-ignore
    // @ts-ignore
    return <Container className={'step-container'} fluid>
        <div className={'step-main-section'}>
            <h2>Documents</h2>
            <br/>

            {/*<Button variant={'outline-success'} disabled={exporting} onClick={*/}
            {/*    () => downloadAction("export_service_packet", `${wi.client.name.firstName} ${wi.client.name.lastName} ${wi.data.case_number} - Service Packet.pdf`)*/}
            {/*}>*/}
            {/*    {exporting ? "Exporting..." : "Download Service Packet"}*/}
            {/*</Button>*/}

            {/*<Button variant={'outline-success'} disabled={exporting} onClick={*/}
            {/*    () => downloadAction("export_filing_packet", `${wi.client.name.firstName} ${wi.client.name.lastName} ${wi.data.case_number} - Filing Packet.pdf`)*/}
            {/*}>*/}
            {/*    {exporting ? "Exporting..." : "Download Filing Packet"}*/}
            {/*</Button>*/}



            <Button variant={'outline-success'} disabled={exporting} onClick={
                () => downloadAction("export_as_zip", `${wi.client.name.firstName} ${wi.client.name.lastName} ${wi.data.case_number} - 1203.4.zip`)
            }>
                {exporting ? "Exporting..." : "Export as Zip"}
            </Button>
            &nbsp;&nbsp;
            <Button variant={'outline-primary'} onClick={
                () => setShowUploadModal(true)
            }>
                Upload File
            </Button>
            {showRegenerate && <>&nbsp;&nbsp;<Button variant={'outline-success'} disabled={regenerating} onClick={regenerateFiles}>{regenerating ? "Regenerating..." : "Regenerate All Files"}</Button></>}
            &nbsp;&nbsp;
            <Button variant={'outline-secondary'} onClick={draftSupplementalDeclaration}>
                Draft Supplemental Declaration
            </Button>
            <br/>
            <br/>

            {uploadedFilesToShow.length > 0 && (<>
                <h5>Uploaded Files</h5>

                <Table>
                    <thead>
                        <tr>
                            <th>File</th>
                            <th>View</th>
                            <th>Download</th>
                        </tr>
                    </thead>
                    <tbody>
                        {uploadedFilesToShow.filter((f) => f.path && f.path.length > 0).map((f) => (<tr>
                            <td>{f.name}</td>
                            <td>
                                <Button size='sm' className={'show-petition-btn'} variant={'outline-primary'} onClick={() => {
                                    showOverlay === f.id ? setShowOverlay("") : setShowOverlay(f.id)
                                }}>
                                    {showOverlay === f.id ? "Hide" : "View"}
                                </Button>
                            </td>
                            <td>
                                <Button size='sm' className={'show-petition-btn'} variant={'outline-primary'} onClick={() => {
                                    downloadFile(f.path, `${wi.client.name.firstName} ${wi.client.name.lastName} ${wi.data.case_number} - ${f.name}`)
                                }}>
                                    Download
                                </Button>
                            </td>
                        </tr>))}
                    </tbody>
                </Table>

                <br/>
            </>)}

            <h5>Packets</h5>

            <Table>
                <thead>
                    <tr>
                        <th>Packet</th>
                        <th>Download</th>
                    </tr>
                </thead>
                <tbody>
                    {packetsToShow.map((f) => (<tr>
                        <td>{f.name}</td>
                        <td>
                            <Button size='sm' className={'show-petition-btn'} variant={'outline-primary'} onClick={() => {
                                downloadAction(f.action, `${wi.client.name.firstName} ${wi.client.name.lastName} ${wi.data.case_number} - ${f.name}.pdf`)
                            }}>
                                Download
                            </Button>
                        </td>
                    </tr>))}
                </tbody>
            </Table>

            <br/>
            <h5>Generated Files</h5>

            <Table>
                <thead>
                    <tr>
                        <th>File</th>
                        <th>View</th>
                        <th>Download</th>
                    </tr>
                </thead>
                <tbody>
                    {filesToShow.filter((f) => f.path && f.path.length > 0).map((f) => (<tr>
                        <td>{f.name}</td>
                        <td>
                            <Button size='sm' className={'show-petition-btn'} variant={'outline-primary'} onClick={() => {
                                showOverlay === f.id ? setShowOverlay("") : setShowOverlay(f.id)
                            }}>
                                {showOverlay === f.id ? "Hide" : "View"}
                            </Button>
                        </td>
                        <td>
                            <Button size='sm' className={'show-petition-btn'} variant={'outline-primary'} onClick={() => {
                                downloadFile(f.path, `${wi.client.name.firstName} ${wi.client.name.lastName} ${wi.data.case_number} - ${f.name}.pdf`)
                            }}>
                                Download
                            </Button>
                        </td>
                    </tr>))}
                </tbody>
            </Table>


        </div>

        {/* @ts-ignore */}
        {filesToShow.concat(uploadedFilesToShow).map((f) => (
            <>
                {wi && showOverlay === f.id && <div className={'rap-overlay'}>
                    <CloseButton onClick={() => setShowOverlay("")}/>
                    <strong className={'ms-2'}>PREVIEW - Changes will not be saved</strong>
                    <div className={'rap-overlay-contents'}>
                        <WorkflowDocView path={f.path} instanceID={wi.uuid}/>
                    </div>
                </div>}
            </>
        ))}


        <div className={`workflow-checkist-bar ${!showTasks && 'full-data'}`}>
            <CA12034DataView wi={wi}/>
            {showTasks && <StepChecklist wi={wi} tasks={currentStepHandler.checkTasks} refresh={refresh}/>}
        </div>

        <ProjectFileUploadModal wi={wi} show={showUploadModal} setShow={setShowUploadModal} refresh={refresh}/>
    </Container>
}


function ArchivedStep({wi, refresh}: StepComponentProps) {
    // @ts-ignore
    return <Container className={'step-container'} fluid>
        <div className={'step-main-section'}>
        </div>
        <div className={'workflow-checkist-bar full-data'}>
            <CA12034DataView wi={wi}/>
        </div>

    </Container>
}

export const CA12034WorkflowHandler: WorkflowHandler = {
    name: "CA12034",
    displayName: "CA 1203.4 Packet Preparation",
    steps: {
        START: {
            sortIdx: 0,
            name: "START",
            displayName: "Created",
            icon: faPlus,
            // @ts-ignore
            component: StartStep,
            checkTasks: []
        },
        CLIENTINFO: {
            sortIdx: 1,
            name: "CLIENTINFO",
            displayName: "Client Info",
            icon: faPerson,
            // @ts-ignore
            component: ClientInfoStep,
            validate: validateClientInfoStep,
            checkTasks: [
                {
                    name: "ci-checkname",
                    description: "Validate client name with case print"
                },
                {
                    name: "ci-checkemail",
                    description: "Add/validate client email with SOT spreadsheet"
                },
                {
                    name: "ci-checkdob",
                    description: "Add/validate client date of birth with rap sheet"
                }
            ]
        },
        CHARGEINFO: {
            sortIdx: 2,
            name: "CHARGEINFO",
            displayName: "Charge Info",
            icon: faGavel,
            // @ts-ignore
            component: ChargeInfoStep,
            validate: validateChargeInfoStep,
            checkTasks: [
                {
                    name: "ci-validatecasenum",
                    description: "Add case number from caseprint (make sure entire case number is entered)"
                },
                {
                    name: "ci-selectcharge",
                    description: "Select charge"
                },
                {
                    name: "ci-validatechargeinfo",
                    description: "Validate charge info"
                }
            ]
        },
        ELIGIBILITY: {
            sortIdx: 3,
            name: "ELIGIBILITY",
            displayName: "Eligibility",
            icon: faPersonCircleCheck,
            // @ts-ignore
            component: EligibilityStep,
            validate: validateEligibilityStep,
            checkTasks: [
                {
                    name: "ci-checkprobationviolation",
                    description: "If probation was granted, check for probation violations"
                }, {
                    name: "ci-validateremedy",
                    description: "Validate remedy (toggle dropdown list for options)"
                },
                {
                    name: "ci-validaterelieftype",
                    description: "Validate relief type (toggle dropdown list for options)"
                },
                {
                    name: "ci-validateeligibility",
                    description: "Validate eligibility based on requirements"
                },
            ]
        },
        COURTINFO: {
            sortIdx: 4,
            name: "COURTINFO",
            displayName: "Court Info",
            icon: faLandmark,
            // @ts-ignore
            component: CourtInfoStep,
            validate: validateCourtInfoStep,
            checkTasks: [
                {
                    name: "ci-selectcourt",
                    description: "Select most recent court listed under Past Proceedings on case print"
                }
            ]
        },
        PETITION: {
            sortIdx: 5,
            name: "PETITION",
            displayName: "Petition",
            icon: faScaleBalanced,
            // @ts-ignore
            component: PetitionStep,
            validate: validatePetitionStep,
            checkTasks: [
                {
                    name: "ci-petition",
                    description: "Complete petition questions"
                }
            ]
        },
        DECLARATION: {
            sortIdx: 6,
            name: "DECLARATION",
            displayName: "Declaration",
            icon: faComment,
            // @ts-ignore
            component: DeclarationStep,
            validate: validateDeclarationStep,
            checkTasks: [
                {
                    name: "ci-declaration-check",
                    description: "Ensure all information and dates in declaration is accurate (especially if using drafting helper)"
                },
                {
                    name: "ci-declaration",
                    description: "Upload signed declaration PDF (if required)"
                }
            ]
        },
        SERVICE: {
            sortIdx: 7,
            name: "SERVICE",
            displayName: "Service",
            icon: faPaperPlane,
            // @ts-ignore
            component: ProofOfServiceStep,
            validate: validateProofOfServiceStep,
            checkTasks: [
                {
                    name: "ci-serviceoffice",
                    description: "Select district/city attorney to serve to"
                },
            ]
        },
        // PACKET: {
        //     sortIdx: 8,
        //     name: "PACKET",
        //     displayName: "Packet",
        //     icon: faFolder,
        //     checkTasks: []
        // },
        REVIEW: {
            sortIdx: 9,
            name: "REVIEW",
            displayName: "Final Review",
            icon: faEye,
            // @ts-ignore
            component: FinalReviewStep,
            checkTasks: [
                {
                    name: "ci-finalreview",
                    description: "Perform final review"
                },
            ]
        },
        READYTOFILE: {
            sortIdx: 10,
            name: "READYTOFILE",
            displayName: "Ready to File",
            icon: faRocket,
            // @ts-ignore
            component: (args) => <FinishedStep showTasks={true} showRegenerate={true} {...args}/>,
            checkTasks: [
                {
                    name: "ci-file",
                    description: "File!"
                },
            ]
        },
        END: {
            sortIdx: 11,
            name: "END",
            displayName: "Finished",
            icon: faCheck,
            // @ts-ignore
            component: FinishedStep,
            checkTasks: []
        },
        archived: {
            sortIdx: 11,
            name: "archived",
            displayName: "Archived",
            icon: faTrash,
            checkTasks: [],
            // @ts-ignore
            component: ArchivedStep,

        },
    }
}